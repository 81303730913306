import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useNavigate } from 'react-router-dom';
import { reducer, INITIAL_STATE } from './tourReducer';

const Tour = ({startWalk, setStartWalk}) => {

  const navigate = useNavigate();
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }
  }, []);

  const setTourViewed = () => {
    setStartWalk(false);
  };

  const callback = (data) => {
    const { action, index, type, status } = data;
  
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const currentStep = tourState.steps[index];
      if (action === ACTIONS.NEXT && currentStep?.data?.next) {
        navigate(currentStep.data.next);
      } else if (action === ACTIONS.PREV && currentStep?.data?.previous) {
        navigate(currentStep.data.previous);
      }
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      });
    }
  };

  return (
    <>
      <JoyRide
        {...tourState}
        run={startWalk}
        disableEnforceFocus
        disableOverlayClose
        stepIndex={tourState.stepIndex}
        callback={callback}
        styles={{
          options: {
            arrowColor: '#CEEA4A',
            backgroundColor: '#ffffff',
            overlayColor: '#444444',
            primaryColor: '#CEEA4A',
            textColor: '#000',
            zIndex: 1000,
          },
          buttonNext: {
            backgroundColor: "#CEEA4A",
            color: "#000",
            borderRadius: "50px",
            paddingInline: "13px",
            paddingBlock: "10px",
            border:"3px solid #CEEA4A",
            cursor: "pointer",
          },
          buttonBack: {
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "50px",
            border:"3px solid #CEEA4A",
            paddingInline: "13px",
            paddingBlock: "10px",
            cursor: "pointer",
          },
          overlay: {
            background: '0px 140px 39px 0px rgba(0, 0, 0, 0.00), 0px 89px 36px 0px rgba(0, 0, 0, 0.01), 0px 50px 30px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.09), 0px 6px 12px 0px rgba(0, 0, 0, 0.10)',
          },
        }}
        locale={{
          next: ">",
          back: "<",
          last: "Finish",
        }}
  
      />
    </>
  );
};

export default Tour;
