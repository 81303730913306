import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "../../../Storage/Redux/registerSlice";
import "./User.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getProducts,getAdminClients } from "../../../Storage/Redux/adminuserSlice";
import * as Yup from "yup";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerData, setRegisterData] = useState({
    firstName: "",
    username: "",
    mailingAddress: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    companyName: "",
    userType: "b_developer",
    products:[]
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectClients, setSelectClients] = useState([]);
  const userData = localStorage.getItem("UserInfo");
  const userInfo = JSON.parse(userData);
  const userId=userInfo?.id;

  const schema = Yup.object().shape({
    firstName: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Special characters and number are not allowed').required("First Name is required"),
    lastName: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Special characters and number are not allowed').required("Last Name is required"),
    // mobile: Yup.string().matches(/^[0-9 -]+$/,'Invalid Phone').required("Phone Number is required"), 
    products: Yup.array()
    .of(Yup.number().required("Product is required"))
    .min(1, "Product is required")
    .required("Product is required"),
    clientId: Yup.string().required("Client is required"),
    email: Yup
    .string()
    .email('Invalid email address')
    .test(
      'not-gmail-yahoo',
      'Only business email is allowed',
      (value) => {
        if (!value) return true;
        const emailDomain = value.split('@')[1];
        return emailDomain !== 'gmail.com' && emailDomain !== 'yahoo.com' ;
      }
    )
    .required('Email is required'),
  });

  useEffect(() => {
    if (userInfo.userType !=="admin") {
      navigate("/");
    }
  }, []);



  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "products") {
      updatedValue = [parseInt(value, 10)]; // Convert value to an integer and wrap it in an array
    }
    if (name === "clientId") {
      updatedValue = parseInt(value, 10); // Convert to an integer
    }
  
    try {
      await schema.validateAt(name, { [name]: updatedValue });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
  
    setRegisterData({
      ...registerData,
      [name]: updatedValue,
      username: registerData?.email,
      roles: [],
      mailingAddress: "",
      adminId: userInfo?.id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(registerData, { abortEarly: false });
      CallApi();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const getProductsFunc=()=>{
    dispatch(getProducts(userId))
     .unwrap()
     .then((data) => {
      setDropdownOptions(data)
        console.log(data,"response");
      })
     .catch((error) => {
        console.log(error, "error is user creation");
      });
  }

  const getAdminClientsFunc=()=>{
    dispatch(getAdminClients(userId))
     .unwrap()
     .then((data) => {
      setSelectClients(data)
        console.log(data,"clients");
      })
     .catch((error) => {
        console.log(error, "error is user creation");
      });
  }

  useEffect(() => {
    getProductsFunc();
    getAdminClientsFunc();
  }, []);

  const CallApi = () => {
    setShowLoader(true);
    dispatch(register(registerData))
      .unwrap()
      .then((data) => {
        console.log(data,"response");
        setErrorMsg("");

        setShowLoader(false);
        localStorage.setItem("listMessage",true)
        navigate("/user-list");
      })
      .catch((error) => {
        
        setShowLoader(false);
        if(error?.message=="Request failed with status code 412"){
          setErrorMsg("User already exists !");
        }
        else{
          setErrorMsg("Failed to create User !");
        }
       
        setTimeout(() => {
          setErrorMsg("");
        }, 5000);

        console.log(error, "error is user creation");
      });
  };
  return (
    <div className="main-div">
      <div className="row-1">
        <div className="main-head heading1">Add New User</div>
      </div>
      {errorMsg !== "" ? (
        <>
          <div className="error-div">
            <div>
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            </div>
            <div>
              <span className="error-text">{errorMsg}</span>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="row-3">
        <div className="field-rows-flex">
          <form onSubmit={handleSubmit}>
          <div className="field-rows-flex-child">
          <div className="label-input-flex">
                <label htmlFor="input1">
                  <span className="red-star">*</span> Client
                </label>
                <select
                  className="form-select"
                  id="clientId"
                  name="clientId"
                  value={registerData?.clientId}
                  onChange={handleInputChange}
                >
                  <option value="0">Select Client</option>
                  {selectClients.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.clientName}
                    </option>
                  ))}
                </select>
                <div>
                  {" "}
                  {errors.clientId !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.clientId}</span>
                  ) : (
                    ""
                  )}
                </div>
            </div>
          </div>
            <div className="field-rows-flex-child">
              <div className="label-input-flex">
                <label htmlFor="firstName">
                  <span className="red-star">*</span> First Name
                </label>
                <input
                  type="text"
                  className="input-style"
                  id="firstName"
                  placeholder="Enter First Name"
                  name="firstName"
                  value={registerData?.firstName}
                  onChange={handleInputChange}
                />
                <div>
                  {" "}
                  {errors.firstName !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.firstName}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="label-input-flex">
                <label htmlFor="middleName" className="lebel-text">
                  Middle Name
                </label>
                <input
                  type="text"
                  className="input-style"
                  id="middleName"
                  placeholder="Enter Middle Name"
                  name="middleName"
                  value={register?.middleName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="field-rows-flex-child">
            <div className="label-input-flex">
                <label htmlFor="lastName" className="lebel-text">
                  <span className="red-star">*</span> Last Name
                </label>
                <input
                  type="text"
                  className="input-style"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter Last Name"
                  value={register?.lastName}
                  onChange={handleInputChange}
                />
                <div>
                  {" "}
                  {errors.lastName !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.lastName}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="label-input-flex">
                <label htmlFor="email">
                  <span className="red-star">*</span> Email
                </label>
                <input
                  type="text"
                  className="input-style"
                  name="email"
                  id="email"
                  placeholder="Enter Email"
                  value={registerData?.email}
                  onChange={handleInputChange}
                />
                <div>
                  {" "}
                  {errors.email !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="field-rows-flex-child">
            <div className="label-input-flex">
                <label htmlFor="mobile">Phone Number
                </label>
                <input
                  type="text"
                  className="input-style"
                  id="mobile"
                  name="mobile"
                  placeholder="Enter Phone Number"
                  value={register?.mobile}
                  onChange={handleInputChange}
                />
                {/* <div>
                  {" "}
                  {errors.mobile !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.mobile}</span>
                  ) : (
                    ""
                  )}
                </div> */}
              </div>
              <div className="label-input-flex">
                <label htmlFor="input1">
                  <span className="red-star">*</span> Product
                </label>
                <select
                  className="form-select"
                  id="products"
                  name="products"
                  value={registerData?.products[0] || ""}
                  onChange={handleInputChange}
                >
                  <option value="0">Choose Product</option>
                  {dropdownOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.description}
                    </option>
                  ))}
                </select>
                <div>
                  {" "}
                  {errors.products !== undefined ? (
                    <span style={{ color: "red", fontSize:"14px" }}>{errors.products}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {showLoader ? (
              <div style={{ textAlign: "center", marginTop: "0px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="#5b72da"
                  spin
                  size="2x"
                />
              </div>
            ) : (
              ""
            )}

            <div className="row-4-flex" style={{marginTop:"100px"}}>
              <button
                type="submit"
                className="button-prime"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="button-sec"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default User;
