import React,{useState} from 'react';
import './TrainingManual.css';
import Accordion from '../TrainingManual/Accordian';
function TrainingManual() {

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
  
    const handleLinkClick = (videoSrc) => {
      setCurrentVideo(videoSrc);
      setIsPopupVisible(true);
    };

    const handleClosePopup = () => {
        setCurrentVideo(null);
        setIsPopupVisible(false);
      };

       const FAQs = [
        { title: '1. How does BlitzPermits work?', content: 'The BlitzPermits uses advanced AI technology to analyze uploaded development plans, cross-reference them against regulatory requirements, and identify compliance issues.<br/><br/> It highlights areas of non-compliance, provides feedback for corrections, and generates detailed reports to help users streamline the approval process.' },
        { title: '2. Can Blitz Permits identify zoning violations in my plan?', content: 'Yes, Blitzpermits evaluates plan against the zoning regulations, focusing on the quantitative and geometric requirements and marks the Non-Compliance in the plans. <br/> <br/> For example: If Minimum Required Lot Area is 10000 sq ft but the plan provides only 8000 sq ft, then BlitzPermits will flag it as a Non-Compliance, allowing you to correct the data.' },
        { title: '3. What kind of compliance checks does BlitzPermits perform?', content: 'BlitzPermits performs comprehensive compliance checks including:<br/><strong>Zoning Codes</strong>, evaluates Site Data, Landscape data, Survey standards for adherence to local regulations.<br/><strong>Accessibility Standards</strong> – Checks for ADA compliance where applicable.' },
        { title: '4. What features does BlitzPermits offer?', content: 'Key Features include:<br/><ol><li>a. Automated plan review for zoning and building code compliance.</li><li>b. Customizable compliance checks for local regulations.</li><li>c. Integration with GIS, Property Appraisers, and other city systems</li><li>d. Detailed reports highlighting Compliance and Non-Compliance and Missing Information</li><li>e. Feedback and suggestions for correcting flagged issues</li><li>f. Secure storage of uploaded plans for ongoing improvement of AI accuracy</li></ol>' },
        { title: '5. What is the maximum file size limit for submissions?', content: 'The maximum file size for submissions is 40 MB.' },
        { title: '6. Does the tool provide feedback for fixing the non-compliant checks?', content: 'Yes, for each non-Compliance check, BlitzPermits provides feedback explaining why it is Non-Compliance. The comments guides the user to make the necessary corrections in your plan.' },
        { title: '7. Can I customize compliance checks to my city or region’s regulations?', content: 'Yes, BlitzPermits allows customization based on city or regional checklists. You can provide your specific requirements, and the team will integrate them into the system' },
        { title: '8. Can the product be integrated with existing systems and software?', content: 'Yes, BlitzPermits can integrate seamlessly with your city or county’s systems, including GIS platforms, property appraisers, and other existing software.' },
        { title: '9. What specific tasks are automated, and what are the expected time savings?', content: 'Automated tasks include:<br/><ol><li>a. Data extraction and validation from plans.</li><li>b.	Zoning and building code compliance checks.</li><li>c. Generation of compliance reports with comments.</li></ol><br/>Automating these processes can reduce review times by up to 70%, enabling faster approvals and more efficient workflows.' },
        { title: '10. Why are some compliance checks missing in the report when information is present in the plan?', content: 'Following could be reasons:<br/><ol><li>a.	Zoning and Geometric information on the plan are not clearly labelled.</li><li>b. Correct type of plan per program is not used. i.e. Site Plan, Survey, Cover for Planning.</li><li>c. Duplicate sheet titles in the plan (e.g., two "Cover Sheet" labels).</li><li>d. Markups, Embedded CAD notes, or Annotations already present in plan, i.e., before submission.</li><li>e. Plan file is hand-drawn or conceptual plans and lacking sufficient data.</li></ol>' },
        { title: '11. Why is my file not uploading?', content: 'Common reasons for upload issues include:<br/><ol><li>a. File size exceeding the 40 MB .</li><li>b.	Unsupported file formats (ensure files are in PDF or a compatible format).</li><li>c. Network issues or server downtime.</li><br/>If the problem persists, contact support at support@blitzpermits.ai</ol>' },
        { title: '12. What is Queue Time?', content: 'Queue time refers to the waiting period before your file is processed. If the server is handling multiple projects simultaneously, your file will be queued and processed in turn.' },
        { title: '13. Is my data secure on BlitzPermits?', content: 'Yes, your data is secured and stored on a very secure cloud server of BlitzPermits.' },
        { title: '14. Does BlitzPermits store my uploaded plans?', content: 'Yes, BlitzPermits stores uploaded plans for AI training purposes and to improve system accuracy. Data is handled in compliance with security and privacy regulations.' },
        { title: '15. How do I contact the customer support team?', content: 'You can contact customer support by mailing to support@blitzpermits.ai' },
        { title: '16. Is any tutorial or demo of the application available?', content: 'Demo videos for different sections of the application will be provided in the Training Manual section of the application.' },
        { title: '17. Is BlitzPermits free to use?', content: 'A trial version will be provided for a limited time, allowing users to explore and learn the tool and provide feedback.' },
        { title: '18. Does BlitzPermits meet the accessibility standards for users with disabilities?', content: 'Yes, BlitzPermits application have been working to meet the accessibility standards for users with disabilities.' },
        { title: '19. Can the tool identify specific ICC code violations in a plan?', content: 'Yes, BlitzPermits identifies violations based on ICC standards, Statewide Codes, municipal supplemental codes and flags areas requiring attention.' },
        { title: '20. Which ICC codebooks do the BlitzPermits support?', content: 'BlitzPermits supports the following ICC codebooks:<br/><ol><li>&bull; <strong>IRC:</strong>International Residential Code</li><li>&bull; <strong>IBC:</strong>International Building Code</li><li>&bull; Statewide Codes, like FBC for Florida, CBC/CRC for California</li></ol>' },
        { title: '21. Can I check ICC code sections relevant to my project', content: 'Yes, BlitzPermits allows users to reference specific ICC code sections applicable to their projects.' },
        { title: '22. Can I customize ICC code checks for local amendments?', content: 'Yes, you can connect with our team for customized checks.' },
        { title: '23. Can BlitzPermits check ADA compliance for my building plan?', content: 'Yes, BlitzPermits can evaluate your building plan for ADA compliance, ensuring accessibility standards are met.' },
    ];
    const Videos = [
      {
          title: 'BlitzPermits Home',
          content: 'Watch video - <a href="#!" data-video="https://blobstorageblitzpermits.blob.core.windows.net/website/product_training/media/video/blitzpermits_home.mp4?sp=r&st=2024-12-04T06:20:58Z&se=2029-12-04T14:20:58Z&spr=https&sv=2022-11-02&sr=b&sig=w8eiNpW15h2Ns%2BoKOehujss0kmJkIoGvnN3JCIl9SUY%3D"><u>link</u></a>',
      },
      {
          title: 'BlitzPermits Dashboard',
          content: 'Watch video - <a href="#!" data-video="https://blobstorageblitzpermits.blob.core.windows.net/website/product_training/media/video/blitzpermits_dashboard.mp4?sp=r&st=2024-12-04T06:20:18Z&se=2029-12-04T14:20:18Z&spr=https&sv=2022-11-02&sr=b&sig=EXotoFzSoeB1zyNj55GzzrQEHo6tbU5it2M9SJPLm0A%3D"><u>link</u></a>',
      },
      {
          title: 'BlitzPermits Project Section',
          content: 'Watch video - <a href="#!" data-video="https://blobstorageblitzpermits.blob.core.windows.net/website/product_training/media/video/blitzpermits_projects_section.mp4?sp=r&st=2024-12-04T06:21:20Z&se=2029-12-04T14:21:20Z&spr=https&sv=2022-11-02&sr=b&sig=3%2FcQec1axQ6r3wCLP1NXfnsbf6oeg%2FBbuN3nJ5bkYmI%3D"><u>link</u></a>',
      },
  ];
    return (
    <div className="main-div">
        <div className="row-1 dashboard-main-div" style={{borderBottom:"2px solid #E6E6E6", paddingBottom:"10px"}}>
            <div className="main-head">Training Manual</div>
        </div>
        <div className="FAQ-sec">
            <h1 className="faq-heading">Training Videos</h1>
            <Accordion items={Videos} onLinkClick={handleLinkClick} />
            <br/>
            <h1 className="faq-heading">Frequently Asked Questions</h1>
            <Accordion items={FAQs} />
        </div>
        {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-button" onClick={handleClosePopup}>
              &times;
            </button>
            <video width="700" height="320" controls autoPlay>
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
    );
}

export default TrainingManual;