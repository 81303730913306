import React from "react";
import "./SandboxWalkthrough.css";
import SandboxLogo from "../../../Assets/Images/blitz_sandbox.png";
import SandboxWalkPlaceholder from "../../../Assets/Images/sandbox-popup-image.png";
import walkthroughClose from "../../../Assets/Images/walkthroughClose.png";
import { Link } from "react-router-dom";
const SandboxWalkthrough = ({closeWalkthrough, startWalkthrough }) => {
  return (
  <div className={`walkthrough-parent`}>
        <div className="walkthrough-child">
          <img
            src={walkthroughClose}
            alt="close"
            className="Walkthrough-close"
            onClick={closeWalkthrough}
          />
          <img src={SandboxWalkPlaceholder} alt="Placeholder" className="sandbox-placeholder-image"/>
          <h4 style={{marginBottom:"0px"}}>Welcome to the</h4>
          <img src={SandboxLogo} alt="SandboxLogo" className="sandboxLogo" />
          <p style={{ textAlign: "center", marginBottom: "0px" }}>
          This exclusive workspace empowers you to start Blitzing through plan reviews with ease, using customized checks tailored for Blitz County’s development services.
          </p>
          <div className="sandbox-walkthrough-steps">
            <p>Follow these steps to get started:</p>
            <p><b>1. Download Plans:</b> Navigate to the <Link to="/resources">Resources tab</Link> and download the Blitz County development plans.</p>
            <p><b>2. Upload & Run a Review:</b> Go to the Projects tab and add plan to review against Blitz County’s customized checks</p>
            <p><b>3. Get Results:</b> From the Projects tab, download the compliance report with detailed redlines</p>
          </div>
          <p style={{ textAlign: "center",marginBottom:"0px" }}>
          Everything you need to revolutionize your code reviews is right here!
          Take a quick tour of the product to guide you through its key features.
            <br />
            Let&apos;s get started!
          </p>
          <button
            className="btn-project"
            style={{ color: "#000" }}
            onClick={startWalkthrough}
          >
            Give me a walkthrough
          </button>
          <p className="sandbox-walkthrough-link" onClick={closeWalkthrough} style={{marginBottom:"0px"}}>
          Skip Tour
          </p>
          <p style={{textAlign:"center", color:"#686868", fontSize:"12px", marginBottom:"0px"}}><i>*NOTE: Your initial journey through the Blitz Sandbox can be completed in under 30 minutes. Subsequent explorations will be even faster. Please ensure you have a stable internet connection.</i></p>
        </div>
    </div>

  );
};

export default SandboxWalkthrough;
