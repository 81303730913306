import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AdminUserService from "../../Services/AdminUserService";

const initialState = {
  users: [],
  products: [],
};

export const getUserList = createAsyncThunk(
  "adminuser/getUserList",
  async (userList) => {
    const res = await AdminUserService.getUserList(userList);
    return res.data;
  }
);

export const getSandboxUserList = createAsyncThunk(
  "/api/user/staging/users",
  async () => {
    const res = await AdminUserService.getSandboxUserList();
    return res.data;
  }
);

export const getProducts = createAsyncThunk(
  "admin/Products",
  async (userId) => {
    const res = await AdminUserService.getProducts(userId);
    return res.data;
  }
);

export const getAdminClients = createAsyncThunk(
  "admin/AdminClients",
  async (userId) => {
    const res = await AdminUserService.getAdminClients(userId);
    return res.data;
  }
);

export const userApproveDeny = createAsyncThunk(
  "/api/admin/service/request",
  async ({ userStatus, requestBody }) => {
    const res = await AdminUserService.userApproveDeny(userStatus, requestBody);
    return res.data;
  }
);

export const registerSandbox = createAsyncThunk(
  "/api/authentication/register",
  async (requestBody) => {
    const res = await AdminUserService.registerSandbox(requestBody);
    return res.data;
  }
);

const adminuserSlice = createSlice({
  name: "adminuser",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(getAdminClients.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(getSandboxUserList.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(userApproveDeny.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(registerSandbox.fulfilled, (state, action) => {
        state.products.push(action.payload);
      });
  },
});

const { reducer } = adminuserSlice;
export default reducer;