import React,{useState,useEffect} from "react";
import "../UserHome/UserHome.css";
import DCardIcon from "../../../Assets/Images/DCardLogo.png";
import Deteroit from "../../../Assets/Images/deteroit.png";
import ProgressBar from "../../../Assets/Images/progress.png";
// import { BarChart } from '@mui/x-charts/BarChart';
import active from "../../../Assets/Images/active.png";
import inactive from "../../../Assets/Images/inactive.png";
import { useDispatch } from "react-redux";
import {
  getClient, getReportChecksSummary, getReportCountSummary, getReportByUser, GetReportAverageTime, GetReportTime
} from "../../../Storage/Redux/projectSlice";
import WalkthroughTooltip from "../../Layouts/common/WalkthroughTooltip";
function UserReports() {
  const [selectedOption, setSelectedOption] = useState("Last 24 hours");
  const [clientName, setClientName] = useState('');
  const [activeCardId, setActiveCardId] = useState(2);
  const [reportChecksSummary, setReportChecksSummary] = useState({
    complianceChecks: 0,
    nonComplianceChecks: 0,
    noInformationChecks: 0,
  });
  const [reportCountSummary, setReportCountSummary] = useState({
    totalProjects: 0,
    completedProjects: 0,
    rejectedProjects: 0,
    resubmittedProjects: 0,
    projectsInProgress: 0
  });
  const [reportTime,setReportTime]= useState({
    projectName:"",
    fileSize: 0,
    blitzTime: 0,
    estimatedManualTime: 0,
    timeSaved: 0,
    percentTimeSaved: 0
  })
  const [reportAverageTime,setReportAverageTime]=useState("");
  const [reportByUser, setReportByUser] = useState([]);
  const dispatch = useDispatch();
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const userId = userDetails?.id || "";
  const clientId = userDetails?.clientId || "";
  const memberId = userDetails?.id || "";
  useEffect(() => {
    dispatch(getClient(memberId)).unwrap()
      .then(response => {
        const clientData = response.resultObj[0];
        if (clientData && clientData.clientName) {
          setClientName(clientData.clientName);
          console.log(clientName)
        }
      })
      .catch(error => {
        console.error('Error fetching client:', error);
      });
  }, [dispatch, memberId]);

  const fetchReportCheckSummary = (timeFrame) => {
    dispatch(getReportChecksSummary({ userId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportChecksSummary(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportCountSummary = (timeFrame) => {
    dispatch(getReportCountSummary({ userId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportCountSummary(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportByUser = (timeFrame) => {
    dispatch(getReportByUser({ userId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportByUser(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchAverageReportTime = (timeFrame) => {
    dispatch(GetReportAverageTime({ userId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportAverageTime(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  const fetchReportTime = (timeFrame) => {
    dispatch(GetReportTime({ userId, timeFrame }))
      .unwrap()
      .then((response) => {
        setReportTime(response);
      })
      .catch((error) => {
        console.error("Error fetching report checks summary:", error);
      });
  };

  useEffect(() => {
    fetchReportCheckSummary(selectedOption);
    fetchReportCountSummary(selectedOption);
    fetchReportByUser(selectedOption);
    fetchAverageReportTime(selectedOption);
    fetchReportTime(selectedOption);
    const intervalId = setInterval(() => {
      fetchReportCheckSummary(selectedOption);
      fetchReportCountSummary(selectedOption);
      fetchReportByUser(selectedOption);
      fetchAverageReportTime(selectedOption);
      fetchReportTime(selectedOption);
    }, 60000);
    return () => clearInterval(intervalId);
  }, [selectedOption, clientId]);

  const handleCardClick = (id) => {
    setActiveCardId(id);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const totalCompletedProjects = (Array.isArray(reportByUser) ? reportByUser : Object.values(reportByUser)).reduce(
    (total, user) => total + (user.completedProjects || 0),
    0
  );

  const isArray = Array.isArray(reportTime);
  
const totalTimeSaved = isArray 
    ? parseFloat(reportTime.reduce((total, item) => total + (item.timeSaved || 0), 0).toFixed(2))
    : 0;
  
  const averageTimeSaved = isArray && reportTime.length > 0 
    ? (totalTimeSaved / reportTime.length).toFixed(2)
    : 0;

    // const totalBlitzTime = isArray 
    // ? reportTime.reduce((total, item) => total + (item.blitzTime || 0), 0)
    // : 0;
  
  // const averageBlitzTimeSaved = isArray && reportTime.length > 0 
  //   ? (totalBlitzTime / reportTime.length).toFixed(2)
  //   : 0;

  //   const totalManualTime = isArray 
  //   ? reportTime.reduce((total, item) => total + (item.estimatedManualTime || 0), 0)
  //   : 0;
  
  // const averageManualTimeSaved = isArray && reportTime.length > 0 
  //   ? (totalManualTime / reportTime.length).toFixed(2)
  //   : 0;

    return (

      <div className="main-div-dashboard">
       <div className="DCard-Container">
        {
            clientName == "City of Detroit" &&
            
        <div 
           className={`DCard ${activeCardId === 1 ? 'activeCard' : ''} overview-card`} 
           onClick={() => handleCardClick(1)}
         >
           {activeCardId === 1 && <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="activeCircle">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg>}
           <p>Overview</p>
           <div className="DCard-inner">
             <div>
               <img src={DCardIcon} />
             </div>
             <p>Check your<br /> teams Progress</p>
           </div>
         </div>
            
        }
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>This is the time saved by Blitzing your review!</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 2 ? 'activeCard' : ''} time-analytics-card`} 
           onClick={() => handleCardClick(2)}
          >
           {activeCardId === 2 && <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="activeCircle">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg>}
           <p>Time Analytics</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{reportAverageTime?.reportAverageTime??0}<span style={{ fontSize: "25px" }}>mins</span></h3>
             <p>Average time taken per Blitz report</p>
           </div>
         </div>
         </WalkthroughTooltip>
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>A breakdown of compliant, non-compliant, and missing checks.</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 3 ? 'activeCard' : ''} compliance-card`} 
           onClick={() => handleCardClick(3)}
         >
           {activeCardId === 3 && <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="activeCircle">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg>}
           <p>Compliance Data</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{reportChecksSummary?.complianceChecks??0}</h3>
             <p>Compliances<br /> found</p>
           </div>
         </div>
         </WalkthroughTooltip>
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>For greater transparency within your team,<br/> product usage details are shared with the Admin.</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 4 ? 'activeCard' : ''} user-management-card`} 
           onClick={() => handleCardClick(4)}
         >
           {activeCardId === 4 && <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="activeCircle">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg>}
           <p>User Management</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#308895" }}>{totalCompletedProjects}</h3>
             <p>Reports<br /> Generated</p>
           </div>
         </div>
         </WalkthroughTooltip>
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>Dashboard is tailored to your specific needs.<br/> To explore additional statistics or metrics,<br/> simply send your requests to <b>sandbox@blitzpermits.ai</b>.</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 5 ? 'activeCard' : ''} geographical-card`} 
           onClick={() => handleCardClick(5)}
         >
          
           {activeCardId === 5 && <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="CardLock">
                                  <path d="M10.2507 37.583C9.31107 37.583 8.50701 37.2487 7.83848 36.5802C7.16996 35.9117 6.83512 35.1071 6.83398 34.1663V17.083C6.83398 16.1434 7.16882 15.3394 7.83848 14.6708C8.50815 14.0023 9.31221 13.6675 10.2507 13.6663H11.959V10.2497C11.959 7.88648 12.7921 5.87236 14.4583 4.2073C16.1245 2.54225 18.1386 1.70915 20.5007 1.70801C22.8627 1.70687 24.8774 2.53997 26.5447 4.2073C28.2121 5.87463 29.0446 7.88876 29.0423 10.2497V13.6663H30.7507C31.6902 13.6663 32.4949 14.0012 33.1645 14.6708C33.8342 15.3405 34.1685 16.1446 34.1673 17.083V34.1663C34.1673 35.1059 33.8331 35.9105 33.1645 36.5802C32.496 37.2499 31.6914 37.5841 30.7507 37.583H10.2507ZM20.5007 29.0413C21.4402 29.0413 22.2449 28.7071 22.9145 28.0386C23.5842 27.37 23.9185 26.5654 23.9173 25.6247C23.9162 24.684 23.5819 23.8799 22.9145 23.2125C22.2471 22.5451 21.4425 22.2103 20.5007 22.208C19.5588 22.2057 18.7547 22.5406 18.0885 23.2125C17.4222 23.8845 17.0874 24.6885 17.084 25.6247C17.0806 26.5608 17.4154 27.3655 18.0885 28.0386C18.7616 28.7116 19.5656 29.0459 20.5007 29.0413ZM15.3757 13.6663H25.6257V10.2497C25.6257 8.82606 25.1274 7.616 24.1309 6.61947C23.1343 5.62294 21.9243 5.12468 20.5007 5.12468C19.077 5.12468 17.867 5.62294 16.8704 6.61947C15.8739 7.616 15.3757 8.82606 15.3757 10.2497V13.6663Z" fill="#444444"/>
                                  </svg>}
           <p style={{color:"#6A6A6A"}}>Geographical Hotspot</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685" }}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
         </WalkthroughTooltip>
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>Dashboard is tailored to your specific needs.<br/> To explore additional statistics or metrics,<br/> simply send your requests to <b>sandbox@blitzpermits.ai</b>.</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 6 ? 'activeCard' : ''} financial-metrics-card`} 
           onClick={() => handleCardClick(6)}
         >
           {activeCardId === 6 && <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="CardLock">
                                  <path d="M10.2507 37.583C9.31107 37.583 8.50701 37.2487 7.83848 36.5802C7.16996 35.9117 6.83512 35.1071 6.83398 34.1663V17.083C6.83398 16.1434 7.16882 15.3394 7.83848 14.6708C8.50815 14.0023 9.31221 13.6675 10.2507 13.6663H11.959V10.2497C11.959 7.88648 12.7921 5.87236 14.4583 4.2073C16.1245 2.54225 18.1386 1.70915 20.5007 1.70801C22.8627 1.70687 24.8774 2.53997 26.5447 4.2073C28.2121 5.87463 29.0446 7.88876 29.0423 10.2497V13.6663H30.7507C31.6902 13.6663 32.4949 14.0012 33.1645 14.6708C33.8342 15.3405 34.1685 16.1446 34.1673 17.083V34.1663C34.1673 35.1059 33.8331 35.9105 33.1645 36.5802C32.496 37.2499 31.6914 37.5841 30.7507 37.583H10.2507ZM20.5007 29.0413C21.4402 29.0413 22.2449 28.7071 22.9145 28.0386C23.5842 27.37 23.9185 26.5654 23.9173 25.6247C23.9162 24.684 23.5819 23.8799 22.9145 23.2125C22.2471 22.5451 21.4425 22.2103 20.5007 22.208C19.5588 22.2057 18.7547 22.5406 18.0885 23.2125C17.4222 23.8845 17.0874 24.6885 17.084 25.6247C17.0806 26.5608 17.4154 27.3655 18.0885 28.0386C18.7616 28.7116 19.5656 29.0459 20.5007 29.0413ZM15.3757 13.6663H25.6257V10.2497C25.6257 8.82606 25.1274 7.616 24.1309 6.61947C23.1343 5.62294 21.9243 5.12468 20.5007 5.12468C19.077 5.12468 17.867 5.62294 16.8704 6.61947C15.8739 7.616 15.3757 8.82606 15.3757 10.2497V13.6663Z" fill="#444444"/>
                                  </svg>}
           <p style={{color:"#6A6A6A"}}>Financial Metrics</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685"}}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
         </WalkthroughTooltip>
         <WalkthroughTooltip position='bottom' content={
            <div>
              <p>Dashboard is tailored to your specific needs.<br/> To explore additional statistics or metrics,<br/> simply send your requests to <b>sandbox@blitzpermits.ai</b>.</p>
            </div>
          }>
         <div 
           className={`DCard ${activeCardId === 7 ? 'activeCard' : ''} project-card`} 
           onClick={() => handleCardClick(7)}
         >
           {activeCardId === 7 && <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="CardLock">
                                  <path d="M10.2507 37.583C9.31107 37.583 8.50701 37.2487 7.83848 36.5802C7.16996 35.9117 6.83512 35.1071 6.83398 34.1663V17.083C6.83398 16.1434 7.16882 15.3394 7.83848 14.6708C8.50815 14.0023 9.31221 13.6675 10.2507 13.6663H11.959V10.2497C11.959 7.88648 12.7921 5.87236 14.4583 4.2073C16.1245 2.54225 18.1386 1.70915 20.5007 1.70801C22.8627 1.70687 24.8774 2.53997 26.5447 4.2073C28.2121 5.87463 29.0446 7.88876 29.0423 10.2497V13.6663H30.7507C31.6902 13.6663 32.4949 14.0012 33.1645 14.6708C33.8342 15.3405 34.1685 16.1446 34.1673 17.083V34.1663C34.1673 35.1059 33.8331 35.9105 33.1645 36.5802C32.496 37.2499 31.6914 37.5841 30.7507 37.583H10.2507ZM20.5007 29.0413C21.4402 29.0413 22.2449 28.7071 22.9145 28.0386C23.5842 27.37 23.9185 26.5654 23.9173 25.6247C23.9162 24.684 23.5819 23.8799 22.9145 23.2125C22.2471 22.5451 21.4425 22.2103 20.5007 22.208C19.5588 22.2057 18.7547 22.5406 18.0885 23.2125C17.4222 23.8845 17.0874 24.6885 17.084 25.6247C17.0806 26.5608 17.4154 27.3655 18.0885 28.0386C18.7616 28.7116 19.5656 29.0459 20.5007 29.0413ZM15.3757 13.6663H25.6257V10.2497C25.6257 8.82606 25.1274 7.616 24.1309 6.61947C23.1343 5.62294 21.9243 5.12468 20.5007 5.12468C19.077 5.12468 17.867 5.62294 16.8704 6.61947C15.8739 7.616 15.3757 8.82606 15.3757 10.2497V13.6663Z" fill="#444444"/>
                                  </svg>}
           <p style={{color:"#6A6A6A"}}>Project Types</p>
           <div className="DCard-inner">
             <h3 className="DData" style={{ color: "#868685" }}>&nbsp;</h3>
             <p style={{color:"#6A6A6A"}}>Coming <br />Soon</p>
           </div>
         </div>
         </WalkthroughTooltip>
       </div>
       <div className="DData-Container">
           {activeCardId === 1 &&  clientName == "City of Detroit" && (
               <div className="OverviewCard-Content">
               <div>
                 <p className="clientName">Welcome Charles Reed,</p>
                 <p>City of Detroit, MI is a <span className="pioneer-text">Pioneer</span> of cutting edge technology</p>
               </div>
               <div className="progressBar-Parent">
                 <div>
                 <img src={ProgressBar}/>
                 </div>
                 <img src={Deteroit}/>
               </div>
               </div>
           )}
           {
             activeCardId===2 && (
             <div className="TimeAnalytics-Content-Parent">
               <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
             <div className="TimeAnalytics-Content">
                 <div className="flex-between">
                     <div>
                     <div className="leftPurpleBorder">
                       <p>Time saved = Faster approvals</p>
                     </div>
                     <div className="graph-color-indicators-parent">
                       {/* <div className="graph-color-indicators">
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#CFC4F1",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}>Blitz Time</p>
                         </div>
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#CFDC67",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}> Reviewer’s Due Diligence</p>
                         </div>
                         <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
                           <div style={{backgroundColor:"#F7CDD3",width:"15px",height:"15px"}}></div>
                           <p style={{marginBottom:"0px"}}>Manual Review </p>
                         </div>
                       </div> */}
                       {/* <div className="timeSaveCard">
                             <h4>{totalTimeSaved} mins</h4>
                             <p>Total estimated time saved</p>
                       </div> */}
                       <div className="timeSaveCard">
                             <h4>{averageTimeSaved} mins</h4>
                             <p>Average time saved per review</p>
                       </div>
                     </div>
                     </div>
                     {/* <div className="graphDiv">
                     <BarChart
                      xAxis={[{ scaleType: 'band', data: [''] }]}
                      series={[{ data: [Number(averageBlitzTimeSaved)], color: '#CFC4F1' }, { data: [Number(averageManualTimeSaved)/3], color: '#CFDC67' }, { data: [Number(averageManualTimeSaved)], color:'#F7CDD3' }]}
                      width={450}
                      height={300}
                    />
                    </div> */}
                       <p className="timeAnalyticsNote">Note: *This is an estimate calculated on the average time taken for reviewing a plan of 1000 square feet.</p>
                     
                 </div>
                 <div>
                     <div className="leftPurpleBorder">
                       <p>Time taken for reports</p>
                     </div>
                     <div className="table-container-div">
                     <table className="styled-table">
                       <thead>
                         <tr>
                           <th style={{fontWeight:"500"}}>Project Name</th>
                           <th style={{fontWeight:"500"}}>File Size</th>
                           <th style={{fontWeight:"500"}}>Blitz Time</th>
                           <th style={{fontWeight:"500"}}>Estimated<br/>Manual Time</th>
                           <th style={{fontWeight:"500"}}>Time Saved</th>
                           <th style={{fontWeight:"500"}}>% Time Saved</th>
                         </tr>
                       </thead>
                       <tbody>
                         { reportTime.length > 0 ? (
                          reportTime.map((reportTime,index)=>(
                            <tr key={index}>
                            <td>{reportTime.projectName}</td>
                            <td>{reportTime.fileSize} mb</td>
                            <td>{reportTime.blitzTime} mins</td>
                            <td>{reportTime.estimatedManualTime} mins</td>
                            <td>{reportTime.timeSaved} mins</td>
                            <td>{reportTime.percentTimeSaved}%</td>
                          </tr>
                          ))):(
                            <>
                            
                            </>
                          )
                         }
                       </tbody>
                     </table>
                     </div>
                 </div>
               </div>
             </div>
             )
           }
           {
             activeCardId===3 && (
               <div className="complianceCard-Content-parent">
                 <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
                 <div className="complianceCard-Content">
                 <div className="summaryChecks-parent">
                     <div className="SummaryChecks">
                       <p>Blitz Detected</p>
                     </div>
                     <div className="DCompParent">
                         <div className="DCompChild">
                           <div >
                           <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46" fill="none" className="left10">
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.9711 45.6891C37.21 45.6891 47.9422 35.5448 47.9422 23.0311C47.9422 10.5174 37.21 0.373047 23.9711 0.373047C10.7322 0.373047 0 10.5174 0 23.0311C0 35.5448 10.7322 45.6891 23.9711 45.6891ZM36.1719 16.8405C36.9242 15.9872 36.8423 14.6855 35.989 13.9332C35.1356 13.1809 33.834 13.2628 33.0817 14.1161L21.6447 27.0886C21.4587 27.2997 21.3076 27.471 21.1744 27.6171C21.0286 27.4837 20.8625 27.3268 20.6581 27.1336L14.7341 21.534C13.9073 20.7526 12.6036 20.7893 11.8222 21.616C11.0407 22.4428 11.0774 23.7465 11.9042 24.5279L17.8282 30.1274L17.8987 30.1941C18.3215 30.5941 18.7764 31.0244 19.2085 31.3341C19.7132 31.6958 20.4361 32.0814 21.3757 32.0386C22.3153 31.9958 23.0002 31.5462 23.47 31.1401C23.8721 30.7925 24.286 30.3226 24.6708 29.8858L24.6708 29.8858L24.7349 29.813L36.1719 16.8405Z" fill="#CEEA4A"/>
                            </svg>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.complianceChecks??0}</p>
                             <p className="comp-text">Code<br/> Compliances</p>
                           </div>
                         </div>
                         <div className="DCompChild">
                           <div>
                           <svg xmlns="http://www.w3.org/2000/svg" width="49" height="46" viewBox="0 0 49 46" fill="none" className="left10">
                            <path fillRule="evenodd" clipRule="evenodd" d="M48.4481 23.0311C48.4481 35.5448 37.7158 45.6891 24.477 45.6891C11.2381 45.6891 0.505859 35.5448 0.505859 23.0311C0.505859 10.5174 11.2381 0.373047 24.477 0.373047C37.7158 0.373047 48.4481 10.5174 48.4481 23.0311ZM13.5347 33.372C12.7079 32.5906 12.7079 31.3236 13.5347 30.5422L21.4833 23.0289L13.5389 15.5197C12.7122 14.7383 12.7122 13.4713 13.5389 12.6898C14.3656 11.9084 15.706 11.9084 16.5328 12.6898L24.4772 20.199L32.4216 12.6898C33.2483 11.9084 34.5887 11.9084 35.4154 12.6898C36.2422 13.4713 36.2422 14.7383 35.4154 15.5197L27.471 23.0289L35.4197 30.5422C36.2464 31.3236 36.2464 32.5906 35.4197 33.372C34.593 34.1535 33.2526 34.1535 32.4258 33.372L24.4772 25.8588L16.5285 33.372C15.7018 34.1535 14.3614 34.1535 13.5347 33.372Z" fill="#E84F4F"/>
                            </svg>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.nonComplianceChecks??0}</p>
                             <p className="comp-text">Code<br/> Violations</p>
                           </div>
                         </div>
                         <div className="DCompChild">
                           <div>
                          <svg width="52" height="58" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg" className="left10">
                            <ellipse cx="26.4688" cy="27.3295" rx="25.5312" ry="25.768" fill="#FFDD43"/>
                            <path d="M25.8226 34.4473C25.2734 34.4473 24.8042 34.2642 24.4151 33.898C24.026 33.509 23.82 33.0398 23.7972 32.4905L23.2822 18.8957C23.2593 18.3693 23.431 17.9115 23.7972 17.5225C24.1633 17.1334 24.6096 16.9388 25.136 16.9388H26.9899C27.5163 16.9388 27.9626 17.1334 28.3288 17.5225C28.6949 17.9115 28.8666 18.3693 28.8437 18.8957L28.3288 32.4905C28.3059 33.0398 28.0999 33.509 27.7108 33.898C27.3217 34.2642 26.8526 34.4473 26.3033 34.4473H25.8226ZM26.6466 37.1938C27.173 37.1938 27.6193 37.3883 27.9855 37.7774C28.3745 38.1664 28.5691 38.6242 28.5691 39.1506V40.0432C28.5691 40.5696 28.3745 41.0273 27.9855 41.4164C27.6193 41.8055 27.173 42 26.6466 42H25.4793C24.9529 42 24.4952 41.8055 24.1061 41.4164C23.7399 41.0273 23.5568 40.5696 23.5568 40.0432V39.1506C23.5568 38.6242 23.7399 38.1664 24.1061 37.7774C24.4952 37.3883 24.9529 37.1938 25.4793 37.1938H26.6466Z" fill="white"/>
                          </svg>
                           </div>
                           <div>
                             <p className="comp-number">{reportChecksSummary?.noInformationChecks??0}</p>
                             <p className="comp-text">Missing<br/> Information</p>
                           </div>
                         </div>
                     </div>
                   </div>
                 <div className="summaryChecks2-parent">
                     <div className="SummaryChecks2">
                       <h2>{reportCountSummary?.completedProjects??0}</h2>
                       <p>Reports<br/> Generated</p>
                     </div>
                     <div className="summaryChecks2-child">
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.totalProjects??0}</h3>
                           <p>Projects<br/> Submitted</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.completedProjects??0}</h3>
                           <p>Projects<br/> Processed</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.projectsInProgress??0}</h3>
                           <p>Projects<br/> In Progress</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.rejectedProjects??0}</h3>
                           <p>Projects<br/> Rejected</p>
                         </div>
                         <div className="summaryChecks2-tile">
                           <h3>{reportCountSummary?.resubmittedProjects??0}</h3>
                           <p>Projects<br/> Resubmitted</p>
                         </div>
                     </div>
                 </div>
                 {/* <div>
                     <div className="topcompliance">
                       <p>Top 3 Compliances</p>
                     </div>
                 </div>
                 <div>
                     <div className="topcompliance">
                       <p>Top 3 Non-Compliances</p>
                     </div>
                 </div> */}
                 </div>
                 
               </div>
             )
           }
           {
             activeCardId===4 && (
               <div className="userManagement-Content-parent">
                 <div className="time-data">
                 <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
                   <option value="Last 24 hours">Last 24 Hours</option>
                   <option value="Last 7 days">Last 7 Days</option>
                   <option value="Last 30 days">Last 30 Days</option>
                   <option value="all">All</option>
                 </select>
                 </div>
                 <div className="userManagement-Content">
                     <div className="leftOrangeBorder">
                       <p>Leaderboard</p>
                     </div>
                     <div className="table-container-div">
                     <table className="styled-table2">
                       <thead>
                         <tr>
                           <th>Member Name</th>
                           <th>Status</th>
                           <th>Project<br/> Submitted</th>
                           <th>Report<br/> Generated</th>
                           <th>Project<br/> In Progress</th>
                           <th>Project<br/> Rejected</th>
                         </tr>
                       </thead>
                       <tbody>
                       {reportByUser.length> 0 ? 
                       
                       (reportByUser.map((user, index) => (
                          <tr key={index}>
                            <td>
                            <svg width="24" height="24" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                              <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                            </svg>
                              &nbsp; {user.userName}
                            </td>
                            <td style={{ color: user.activationStatus === "Active" ? "#543CA1" : "#889395" }}>
                              <img
                                src={user.activationStatus === "Active" ? active : inactive}
                                alt="Status"
                              />
                              &nbsp; {user.activationStatus}
                            </td>
                            <td>{user.totalProjects}</td>
                            <td>{user.completedProjects}</td>
                            <td>{user.projectsInProgress}</td>
                            <td>{user.rejectedProjects}</td>
                          </tr>
                        ))):
                        (
                          <>
                          
                          </>
                        )}
                       </tbody>
                     </table>
                     </div>
                 </div>  
               </div>
             )
           }
          
           {
             activeCardId === 5 && (
            //   <div className="geographicalData-Content-parent">
            //  <div className="time-data">
            //      <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
            //        <option value="Last 24 hours">Last 24 Hours</option>
                  //  <option value="Last 7 days">Last 7 Days</option>
                  //  <option value="Last 30 days">Last 30 Days</option>
                  //  <option value="all">All</option>
            //      </select>
            //      </div>
            //   <div className="geographicalData-Content">
            //     <div className="geo-money-stat-parent">
            //       <div className="geo-money-stat">
            //         <h2>$xx,xxx</h2>
            //         <p>Saved</p>
            //       </div>
            //       <div>
            //         <p><i>Note: Estimated hourly pay $ 30</i></p>
            //       </div>
            //     </div>
            //     <div className="geog-card-parent">
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>410%</h3>
            //             <p>faster permit<br/> issuance</p>
            //           </div>
            //           <div className="geog-card">
            //             <h3>3.5X</h3>
            //             <p>increased reviewer<br/> productivity</p>
            //           </div>
            //       </div>
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>83%</h3>
            //             <p>reduced manual<br/> review time</p>
            //           </div>
            //           <div className="geog-card">
            //             <h3>168%</h3>
            //             <p>uptick in annual<br/> increased in property<br/> tax revenue</p>
            //           </div>
            //       </div>
            //       <div className="geog-card-child">
            //           <div className="geog-card">
            //             <h3>8%</h3>
            //             <p>reduction in cost <br/>of new real estate <br/>for citizens</p>
            //           </div>
            //           <div className="thunder-image-cntr">
            //             <img src={ThunderImage} height={60}/>
            //           </div>
            //       </div>
            //     </div>
            //   </div>
            //  </div>
            <div>
            </div>
           )}
           {
             activeCardId === 6 && (
            <div>
            </div>
           )}
            {
             activeCardId===7 && (
              // <div className="projectTypes-Content-parent">
              //   <div className="time-data">
              //    <select id="time-filter" value={selectedOption} onChange={handleChange} className="custom-dropdown">
              //      <option value="Last 24 hours">Last 24 Hours</option>
              //      <option value="this week">This Week</option>
              //      <option value="this month">This Month</option>
              //      <option value="this year">This Year</option>
              //      <option value="all">All</option>
              //    </select>
              //    </div>
              //    <div className="projectTypes-Content">
              //     <div className="projectTypes-flex">
              //       <div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //       </div>
              //       <div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //         <div className="projectTypes-number">
              //           <h2>00</h2>
              //           <p>abcde</p>
              //         </div>
              //       </div>
              //     </div>
              //       <div className="projectTypes-map">
              //       <img src={projectTypesMaps} height={400}/>
              //       </div>
              //     </div>
              //  </div>
              <div>
              </div>
             )
           }
       </div>
     </div>

    );
 
}
export default UserReports;