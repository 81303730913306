import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectDataService from "../../Services/ProjectService";
// import LoginDataService from "../../services/LoginService";

const initialState = [];

export const getClient = createAsyncThunk(
  "project/getClient",
  async (userId) => {
    const res = await ProjectDataService.getClient(userId);
    return res.data;
  }
);

//move to file service

// export const saveFile = createAsyncThunk("login/saveFile", async (fileData) => {
//   const res = await ProjectDataService.saveFile(fileData);
//   console.log(res, "servicesspage");
//   return res.data;
// });

export const saveFileData = createAsyncThunk(
  "project/saveFileData",
  async (resetData) => {
    const res = await ProjectDataService.saveFileData(resetData);

    return res.data;
  }
);

export const createProject = createAsyncThunk(
  "project/createProject",
  async (projectData) => {
    const res = await ProjectDataService.createProject(projectData);
    return res.data;
  }
);


export const getCounty = createAsyncThunk(
  "project/getCounty",
  async (countyData) => {
    const res = await ProjectDataService.getCounty(countyData);
    return res.data;
  }
);

export const getReportChecksSummary = createAsyncThunk(
  "Dashboard/ReportChecksSummary",
  async ({userId,timeFrame}) => {
    const res = await ProjectDataService.getReportChecksSummary(userId,timeFrame);
    return res.data;
  }
);

export const getReportCountSummary = createAsyncThunk(
  "Dashboard/ReportCountSummary",
  async ({userId,timeFrame}) => {
    const res = await ProjectDataService.getReportCountSummary(userId,timeFrame);
    return res.data;
  }
);

export const getReportByUser = createAsyncThunk(
  "Dashboard/ReportByUser",
  async ({userId,timeFrame}) => {
    const res = await ProjectDataService.getReportByUser(userId,timeFrame);
    return res.data;
  }
);

export const GetReportAverageTime = createAsyncThunk(
  "Dashboard/ReportAverageTime",
  async ({userId,timeFrame}) => {
    const res = await ProjectDataService.GetReportAverageTime(userId,timeFrame);
    return res.data;
  }
);

export const GetReportTime = createAsyncThunk(
  "Dashboard/ReportTime",
  async ({userId,timeFrame}) => {
    const res = await ProjectDataService.GetReportTime(userId,timeFrame);
    return res.data;
  }
);

export const getProjectList = createAsyncThunk(
  "project/getProjectList",
  async (data) => {
    const res = await ProjectDataService.getProjectList(data);
    return res.data;
  }
);

export const GetSandboxFileStatus = createAsyncThunk(
  "File/SandboxFileStatus",
  async (userId) => {
    const res = await ProjectDataService.GetSandboxFileStatus(userId);
    return res.data;
  }
);
export const GetDownloadSandboxFile = createAsyncThunk(
  "File/DownloadSandboxFile",
  async ({userId,fileType,number}) => {
    const res = await ProjectDataService.GetDownloadSandboxFile(userId,fileType,number);
    return res.data;
  }
);

export const getPlanType = createAsyncThunk(
  "project/plan-type",
  async (getPlanType) => {
    const res = await ProjectDataService.getPlanType(getPlanType);
    return res.data;
  }
);

export const getConstructionType = createAsyncThunk(
  "project/construction-type",
  async (getConstructionType) => {
    const res = await ProjectDataService.getConstructionType(getConstructionType);
    return res.data;
  }
);

export const getUserInfo = createAsyncThunk(
  "project/getUserInfo",
  async (getUserInfo) => {
    const res = await ProjectDataService.getUserInfo(getUserInfo);
    return res.data;
  }
);

export const getReviewType = createAsyncThunk(
  "project/getReviewType",
  async (reviewTypeData) => {
    const res = await ProjectDataService.getReviewType(reviewTypeData);

    return res.data;
  }
);

export const getProductReviewType = createAsyncThunk(
  "project/getProductReviewType",
  async ({ countyId, productId }) => {
    const res = await ProjectDataService.getProductReviewType(countyId, productId);

    return res.data;
  }
);

export const getZoning = createAsyncThunk(
  "project/getZoning",
  async (zoningId) => {
    const res = await ProjectDataService.getZoning(zoningId);

    return res.data;
  }
);

export const getQueuePositions = createAsyncThunk(
  "project/queue-positions",
  async (projectIds) => {
    const res = await ProjectDataService.getQueuePositions(projectIds);
    return res.data;
  }
);

export const getCountyProduct = createAsyncThunk(
  "project/countyproductconfig",
  async ({ countyId, productId }) => {
    const res = await ProjectDataService.getCountyProduct(countyId, productId);
    return res.data;
  }
);

const reportSlice = createSlice({
  name: "project",
  initialState,
  extraReducers: {
    [getClient.fulfilled]: (state, action) => {
      state.push(action.payload);
    },

    [getCounty.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },

    [getReviewType.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getProjectList.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getUserInfo.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [createProject.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getCountyProduct.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getQueuePositions.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getPlanType.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getConstructionType.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getReportChecksSummary.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getReportCountSummary.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getReportByUser.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [GetReportAverageTime.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [GetReportTime.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [getProductReviewType.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [GetSandboxFileStatus.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    },
    [GetDownloadSandboxFile.fulfilled]: (state, action) => {
      //return [...action.payload];
      state.push(action.payload);
    }
  },
});

const { reducer } = reportSlice;
export default reducer;
