import http from "../http-common";

const getUserList = (timeframe) => {
  return http.get(`/api/user?timeframe=${timeframe}`);
};

const getSandboxUserList = () => {
  return http.get(`/api/user/staging/users`);
};

const getProducts = (userId) => {
  return http.get(`/api/admin/products/${userId}`);
};

const getAdminClients = (userId) => {
  return http.get(`/api/admin/AdminClients/${userId}`);
};

const userApproveDeny = (userStatus, requestBody) => {
  return http.put(`/api/admin/service/request?status=${userStatus}`, requestBody);
}

const registerSandbox=(requestBody)=>{
  return http.post(`/api/authentication/register`,requestBody);
}

const AdminUserService = {
  getUserList,
  getProducts,
  getAdminClients,
  getSandboxUserList,
  userApproveDeny,
  registerSandbox
};

export default AdminUserService;
