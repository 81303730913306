import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const RouteGuard = ({ requiredProductId }) => {

  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const licenseData = userDetails?.licenseData || [];
  const hasAccess = licenseData.some(
    (license) => license.productId === requiredProductId
  );
  console.log(requiredProductId,"License")
  if (!hasAccess) {
    return <Navigate to="/user-home" />;
  }
  return <Outlet />;
};

export default RouteGuard;
