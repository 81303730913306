import React, { useState, useEffect, useRef  } from 'react';
import './Tooltip2.css';
import cross from "../../../Assets/Images/cross.png"

const Tooltip2 = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);

  const handleTooltip = () => {
    setIsVisible(true);
  };

  const HideTooltip = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    // Function to handle clicks outside the tooltip
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        HideTooltip(); // Hide the tooltip if clicked outside
      }
    };

    // Add a click event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="tooltip-container2" onClick={handleTooltip} ref={tooltipRef}>
      {children}
      {isVisible &&
        <div className="tooltip-content2">
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <h6>Input Error</h6> 
                <img src={cross} onClick={(e) => { e.stopPropagation(); HideTooltip(); }} style={{cursor:"pointer"}}/>
                </div>
            <p style={{lineHeight:"17px", fontSize:"15px"}}>Input error. Processing of file terminated. <br/>Review the submitted file. <br/>Contact support for further assistance.</p>
       </div>
       }
    </div>
  );
};

export default Tooltip2;
