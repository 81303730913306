import React, {useEffect,useRef} from 'react';
import './UserCard.css';
import Logout from "../../../Assets/Images/logout.png";
import X from "../../../Assets/Images/x.png";


const UserCard = ({getInitials, handleEmail, setCardVisible, handleLogout}) => {
    const cardRef = useRef(null);
    const crossClicked = () => {
        setCardVisible(false);
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setCardVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setCardVisible]);

  return (
    <div className='userCard' ref={cardRef}>
        <div className='userCard-main'>
        <div className='cross'>
            <img src={X} alt="cross" onClick={crossClicked}/>
        </div>
        <div>
            <p className='card-font' style={{marginBottom:"0px"}}>
                {handleEmail()}
            </p>
        </div>
        <div className="profile-initials-parent">
            <h5 className='profile-initials-inner'>
            {getInitials()}
            </h5>
        </div>
        <div >
            <button className='manage-button card-font'>
                <a href="mailto:sandbox@blitzpermits.ai"><span style={{color:"black"}}>Support</span></a>
            </button>
        </div>
        <div>
            <button className='logout-button' onClick={handleLogout}>
                <p className='card-font'>Logout</p>
                <img src={Logout} alt='logout'/>
            </button>
        </div>
        </div>
    </div>
  )
}

export default UserCard