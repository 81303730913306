import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../Storage/Redux/loginSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import PasswordPolicy from "../../Layouts/PasswordPolicy.js";
import BlitzYellowLogo from "../../../Assets/Images/BlitzYellowLogo.png";
import "../User/Login.css";

const Reset = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlSearch = useLocation();
  const cursorPositionRef = useRef(null);
  const userMail = localStorage.getItem("userEmail");
  const tokenData = urlSearch.search;
  const tokenMail = tokenData.split("&email=");
  const mailValue = tokenMail[1];
  const tokenValue = tokenMail[0].split("?token=")[1];
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetData, setResetdata] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const usermail = localStorage.getItem("userEmail");
  const [showPasswordPolicy, setShowPasswordPolicy] = useState(false);
  const schema = Yup.object().shape({
    password: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{10,}$/,
      `Password doesn't meet our security requirements`
    ),
    confirmPassword: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{10,}$/,
      `Password doesn't meet our security requirements`
    ),
  });
  useEffect(() => {
    if (
      (tokenValue === null || tokenValue === undefined) &&
      (mailValue === null || mailValue === undefined)
    ) {
      if (userMail === null || userMail === undefined) {
        navigate("/");
      }
    }
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;
    cursorPositionRef.current = cursorPosition;
    try {
      await schema.validateAt(name, { [name]: value });
      setErrors({ ...errors, [name]: "" });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
    setResetdata({
      ...resetData,
      [name]: value,
      email: usermail === null ? mailValue : usermail,
      token:
        location.state === null || undefined
          ? tokenValue
          : location?.state?.responseObject,
    });
    setTimeout(() => {
      e.target.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(resetData, { abortEarly: false });
      CallApiData();
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const CallApiData = () => {
    setShowLoader(true);
    dispatch(resetPassword(resetData))
      .unwrap()
      .then((data) => {
        console.log(data);
        setErrorMsg("");
        setSubmitted(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      })
      .catch((e) => {
        setShowLoader(false);
        setErrorMsg(
          "Password provided doesn't meet our security requirements"
        );
        console.log(e, "error");
      });
  };
  const handlePolicy = () => {
    setShowPasswordPolicy(true);
  };
  return (
    <div className="login-container-new">
      <div className="login-screen-gradient">
              <img src={BlitzYellowLogo} className="LoginLogo"/>
              <img
                src={require("../../../Assets/Images/new-gradient-background.png")}
                className="back-grad"
              />
            </div>
      <div className="new-login-main">
        <div className="login-form">
          <div className="logo-div">
            <img
              src={require("../../../Assets/Images/Blitz_Permits_Logo.png")}
              alt="logo"
            />
          </div>
          <div className="policy-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
            >
              <path
                d="M13.1259 0C16.6072 0 19.9458 1.38291 22.4074 3.8445C24.869 6.30609 26.2519 9.64472 26.2519 13.1259C26.2519 16.6072 24.869 19.9458 22.4074 22.4074C19.9458 24.869 16.6072 26.2519 13.1259 26.2519C9.64472 26.2519 6.30609 24.869 3.8445 22.4074C1.38291 19.9458 0 16.6072 0 13.1259C0 9.64472 1.38291 6.30609 3.8445 3.8445C6.30609 1.38291 9.64472 0 13.1259 0ZM15.0947 8.05875C16.0697 8.05875 16.8609 7.38188 16.8609 6.37875C16.8609 5.37562 16.0678 4.69875 15.0947 4.69875C14.1197 4.69875 13.3322 5.37562 13.3322 6.37875C13.3322 7.38188 14.1197 8.05875 15.0947 8.05875ZM15.4378 18.6094C15.4378 18.4088 15.5072 17.8875 15.4678 17.5912L13.9266 19.365C13.6078 19.7006 13.2084 19.9331 13.0209 19.8713C12.9359 19.84 12.8648 19.7793 12.8204 19.7003C12.7761 19.6212 12.7614 19.5289 12.7791 19.44L15.3478 11.325C15.5578 10.2956 14.9803 9.35625 13.7559 9.23625C12.4641 9.23625 10.5628 10.5469 9.40594 12.21C9.40594 12.4088 9.36844 12.9037 9.40781 13.2L10.9472 11.4244C11.2659 11.0925 11.6372 10.8581 11.8247 10.9219C11.9171 10.955 11.9928 11.0231 12.0355 11.1114C12.0783 11.1998 12.0847 11.3013 12.0534 11.3944L9.50719 19.47C9.21281 20.415 9.76969 21.3413 11.1197 21.5513C13.1072 21.5513 14.2809 20.2725 15.4397 18.6094H15.4378Z"
                fill="#523CA2"
              />
            </svg>
            <p onClick={handlePolicy} style={{ marginBottom: "0rem" }}>
              Password Policy
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 10L12 15L17 10H7Z"
                fill="#523CA2"
                onClick={handlePolicy}
              />
            </svg>
          </div>
          <div className="show-policy">
            {showPasswordPolicy && (
              <PasswordPolicy setShowPasswordPolicy={setShowPasswordPolicy} />
            )}
          </div>
          <h2 style={{ marginBottom: "0.8rem" }}>Reset Password</h2>
          <p className="below-heading-text">Enter your new password</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="new_password" className="new-login-label">
                <span className="red-star">
                  <sup>*</sup>
                </span>
                New Password
              </label>
              <div className="password-input">
                <input
                  className="input-new-login"
                  type={showNewPassword ? "text" : "password"}
                  id="new_password"
                  value={resetData?.password || ""}
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              <div>
                {errors.password && (
                  <span
                    style={{
                      color: "red",
                      "font-size": "14px",
                      paddingLeft: "5px",
                    }}
                  >
                    {errors.password}
                  </span>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="confirm_password" className="new-login-label">
                <span className="red-star">
                  <sup>*</sup>
                </span>
                Confirm Password
              </label>
              <div className="password-input">
                <input
                  className="input-new-login"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={resetData?.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirm your password"
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              </div>
              <div>
                {errors.confirmPassword && (
                  <span
                    style={{
                      color: "red",
                      "font-size": "14px",
                      paddingLeft: "5px",
                    }}
                  >
                    {errors.confirmPassword}
                  </span>
                )}
              </div>
            </div>
            {showLoader ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  color="#5b72da"
                  spin
                  size="2x"
                />
              </div>
            ) : (
              ""
            )}
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                color: "red",
              }}
            >
              {errorMsg !== "" ? errorMsg : ""}
            </p>
            <button type="submit" className="login-button">
              Reset Password
            </button>
          </form>
          {submitted === true ? (
            <div
              className="success-div-2"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
                color: "#1E7137",
                paddingTop: "10px",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "#1E7137", height: 10, with: 10 }}
                />
              </div>
              <div className="reset-text">Reset password successfully done</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Reset;
