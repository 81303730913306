
import React from 'react';

// const PlanningWalkthrough = [
//     {
//       target: "#step1",
//       content: (
//         <p style={{margin:"0px"}}>Explore solutions for all Development Services Departments. Access your selected product in the Sandbox to experience it firsthand.</p>
//       ),
//       data: {
//         next: '/user-home',
//       },
//       disableBeacon: true
//     },
//     {
//       target: "#step2",
//       content: (
//         <div style={{textAlign:'left'}}>
//         <p style={{margin:"0px"}}>Active products are displayed on your home screen upon login:<br/> <b>&bull; Blitz Planning:</b> For Plan and Zoning Reviews <br/><b>&bull; Blitz Building:</b> For Residential Building Code Reviews<br/> &bull; <b>Blitz Jarviz:</b> AI Assistant for code queries, comparisons and updates</p>
//       </div>),
//       data: {
//         previous: '/user-home',
//         next: '/user-home',
//       }
//     },
//     {
//       target: "#skip",
//       content: (
//         <div style={{textAlign:'left'}}>
//         <p style={{margin:"0px"}}>&nbsp;</p>
//       </div>),
//       data: {
//         previous: '/user-home',
//         next: '/blitz-planning',
//       }
//     },
//     {
//       target: "#step3",
//       content: (
//         <p style={{margin:"0px"}}>Welcome to the Projects page - where you will find the Project Status and Review Reports.</p>
//       ),
//       data: {
//         previous: '/blitz-planning',
//         next: '/blitz-planning',
//       }
//     },
//     {
//         target: "#step4",
//         content: (
//           <p style={{margin:"0px"}}>The Sandbox is configured for Blitz County, using codes relevant to its reviewers (see the User Manual for a detailed list of checks in the sandbox). When you sign up for the full version, the product will be customized for your jurisdiction&apos;s needs.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/blitz-planning',
//         }
//       },
//       {
//         target: "#step5",
//         content: (
//           <p style={{margin:"0px"}}>Real time project review Updates</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/blitz-planning',
//         }
//       },
//       {
//         target: "#step6",
//         content: (
//           <p style={{margin:"0px"}}>In the Sandbox, you test alongside other users in a shared queue. When you sign up for the full version, you have a dedicated server and are not queued.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/blitz-planning',
//         }
//       },
//       {
//         target: "#step7",
//         content: (
//           <div style={{textAlign:'left'}}>
//           <p style={{margin:"0px"}}>Two report types are generated for you:<br/> <b>1. Review Report –</b> Contains only the <br/>compliance check results<br/> <b>2. Annotated Report –</b> Includes the Review<br/> Report along with redline markups on the<br/> submission<br/></p>
//           <p style={{color:'#8A8A8A'}}><i>*Red and green line markups are<br/> hyperlinked within both the Review Report and the drawings</i></p>
//         </div>),
//         data: {
//         previous: '/blitz-planning',
//         next: '/blitz-planning',
//       }
//       },
//       {
//         target: "#step8",
//         content: (
//         <p style={{margin:"0px"}}>In the sandbox, you can upload Blitz County Plans from the Resources tab.<br/>The Sandbox supports only these sample plans. You&apos;ll unlock the ability to Blitz your plans, tailored to your local codes, With full customization.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/blitz-planning',
//         }
//       },
//       {
//         target: "#step9",
//         content: (
//           <p style={{margin:"0px"}}>Blitzing your plans in the sandbox provides valuable insights, captured in the Dashboard for easy tracking and analysis.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/blitz-planning',
//         }
//       },
//     {
//         target: "#step10",
//         content: (
//           <p style={{margin:"0px"}}>The Blitz County Development Plans Project submissions and user manual are under Resources.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/resources',
//         }
//       },
//       {
//         target: "#step11",
//         content: (
//           <p style={{margin:"0px"}}>In addition to all product features, you can view the detailed list of live checks for Blitz County reviewers within the Sandbox here.</p>
//         ),
//         data: {
//           previous: '/blitz-planning',
//           next: '/resources',
//         }
//       },
//       {
//         target: "#step12",
//         content: (
//           <p style={{margin:"0px"}}>The Blitz County Development Plans to Upload and run are available here. Add these as new projects within the Sandbox to experience the workflow. Start Blitzing your review!</p>
//         ),
//         data: {
//           previous: '/resources',
//           next: '/resources',
//         }
//       },
//       {
//         target: "#step13",
//         content: (
//           <p style={{margin:"0px"}}>You are all set!<br/> If you want to access this guided tour again, please click here.<br/> Happy Blitzing!</p>
//         ),
//         data: {
//           previous: '/resources',
//           next: '/user-home',
//         }
//       }
//   ];
  
  const ResidentialWalkthrough = [
    {
      target: "#step1",
      content: (
        <p style={{margin:"0px"}}>Explore solutions for all Development Services Departments. Access your registered product in the Sandbox to experience it firsthand.</p>
      ),
      data: {
        next: '/user-home',
      },
      disableBeacon: true
    },
    {
      target: "#step2",
      content: (
        <div style={{textAlign:'left'}}>
        <p style={{margin:"0px"}}>Active products are displayed on your home screen upon login:<br/> <b>&bull; Blitz Planning:</b> For Plan and Zoning Reviews <br/><b>&bull; Blitz Building:</b> For Residential Building Code Reviews<br/> &bull; <b>Blitz Jarviz:</b> AI Assistant for code queries, comparisons and updates</p>
      </div>),
      data: {
        previous: '/user-home',
        next: '/user-home',
      }
    },
    {
      target: "#skip",
      content: (
        <div style={{textAlign:'left'}}>
        <p style={{margin:"0px"}}>&nbsp;</p>
      </div>),
      data: {
        previous: '/user-home',
        next: '/blitz-planning',
      }
    },
          {
            target: "#step3",
            content: (
              <p style={{margin:"0px"}}>Welcome to the Projects page - where you will find the Project Status and Review Reports.</p>
            ),
            data: {
              previous: '/blitz-planning',
              next: '/blitz-planning',
            }
          },
          {
              target: "#step4",
              content: (
                <p style={{margin:"0px"}}>The Sandbox is configured for Blitz County, using codes relevant to its reviewers (see the User Manual for a detailed list of checks in the sandbox). When you sign up for the full version, the product will be customized for your jurisdiction&apos;s needs.</p>
              ),
              data: {
                previous: '/blitz-planning',
                next: '/blitz-planning',
              }
            },
            {
              target: "#step5",
              content: (
                <p style={{margin:"0px"}}>Real time project review Updates</p>
              ),
              data: {
                previous: '/blitz-planning',
                next: '/blitz-planning',
              }
            },
            {
              target: "#step7",
              content: (
                <div style={{textAlign:'left'}}>
                <p style={{margin:"0px"}}>Download the Annotated Report which includes the Compliance Report along with redline markups on the submission<br/></p>
                <br/>
                <p style={{color:'#8A8A8A'}}><i>*Red and green line markups are hyperlinked between the Report and the drawings</i></p>
              </div>),
              data: {
              previous: '/blitz-planning',
              next: '/blitz-planning',
            }
            },
            {
              target: "#step8",
              content: (
              <p style={{margin:"0px"}}>In the sandbox, you can upload Blitz County Plans from the Resources tab.<br/>The Sandbox supports only these sample plans. You&apos;ll unlock the ability to Blitz your plans, tailored to your local codes, With full customization.</p>
              ),
              data: {
                previous: '/blitz-planning',
                next: '/blitz-planning',
              }
            },
            {
              target: "#skip",
              content: (
                <div style={{textAlign:'left'}}>
                <p style={{margin:"0px"}}>&nbsp;</p>
              </div>),
              data: {
                previous: '/user-home',
                next: '/blitz-residential-building',
              }
            },
    {
      target: "#stepR3",
      content: (
        <p style={{margin:"0px"}}>Welcome to the Projects page - where you will find the Project Status and Review Reports.</p>
      ),
      data: {
        previous: '/blitz-residential-building',
        next: '/blitz-residential-building',
      }
    },
    {
        target: "#stepR4",
        content: (
          <p style={{margin:"0px"}}>The Sandbox is configured for Blitz County, using codes relevant to its reviewers (see the User Manual for a detailed list of checks in the sandbox). When you sign up for the full version, the product will be customized for your jurisdiction&apos;s needs.</p>
        ),
        data: {
          previous: '/blitz-residential-building',
          next: '/blitz-residential-building',
        }
      },
      {
        target: "#stepR5",
        content: (
          <p style={{margin:"0px"}}>Real time project review Updates</p>
        ),
        data: {
          previous:  '/blitz-residential-building',
          next: '/blitz-residential-building',
        }
      },
      {
        target: "#stepR7",
        content: (
          <div style={{textAlign:'left'}}>
         <p style={{margin:"0px"}}>Download the Annotated Report which includes the Compliance Report along with redline markups on the submission<br/></p>
            <br/>
          <p style={{color:'#8A8A8A'}}><i>*Red and green line markups are hyperlinked between the Report and the drawings</i></p>
        </div>),
        data: {
          previous:  '/blitz-residential-building',
          next:  '/blitz-residential-building',
        }
      },
      {
        target: "#stepR8",
        content: (
        <p style={{margin:"0px"}}>In the sandbox, you can upload Blitz County Plans from the Resources tab.<br/>The Sandbox supports only these sample plans. You&apos;ll unlock the ability to Blitz your plans, tailored to your local codes, With full customization.</p>
        ),
        data: {
          previous:  '/blitz-residential-building',
          next:  '/blitz-residential-building',
        }
      },
      {
        target: "#step9",
        content: (
          <p style={{margin:"0px"}}>Blitzing your plans in the sandbox provides valuable insights, captured in the Dashboard for easy tracking and analysis.</p>
        ),
        data: {
          previous:  '/blitz-residential-building',
          next:  '/resources',
        }
      
      },
    {
        target: "#step10",
        content: (
          <p style={{margin:"0px"}}>The Blitz County Development Plans Project submissions and user manual are under Resources.</p>
        ),
        data: {
          previous: '/resources',
          next: '/resources',
        }
      },
      {
        target: "#stepR11",
        content: (
          <p style={{margin:"0px"}}>In addition to all product features, you can view the detailed list of live checks for Blitz County reviewers within the Sandbox here.</p>
        ),
        data: {
          previous: '/resources',
          next: '/resources',
        }
      },
      {
        target: "#stepR12",
        content: (
          <p style={{margin:"0px"}}>The Blitz County Development Plans to Upload and run are available here. Add these as new projects within the Sandbox to experience the workflow. Start Blitzing your review!</p>
        ),
        data: {
          previous: '/resources',
          next: '/resources',
        }
      },
      {
                target: "#step11",
                content: (
                  <p style={{margin:"0px"}}>In addition to all product features, you can view the detailed list of live checks for Blitz County reviewers within the Sandbox here.</p>
                ),
                data: {
                  previous: '/blitz-planning',
                  next: '/resources',
                }
              },
              {
                target: "#step12",
                content: (
                  <p style={{margin:"0px"}}>The Blitz County Development Plans to Upload and run are available here. Add these as new projects within the Sandbox to experience the workflow. Start Blitzing your review!</p>
                ),
                data: {
                  previous: '/resources',
                  next: '/resources',
                }
              },
      {
        target: "#step13",
        content: (
          <p style={{margin:"0px"}}>You are all set!<br/> If you want to access this guided tour again, please click here.<br/> Happy Blitzing!</p>
        ),
        data: {
          previous: '/resources',
          next: '/resources',
        }
      }
  ];
// const userDataInfo = localStorage.getItem("UserInfo");
// const userDetails = JSON.parse(userDataInfo);
// const licenseData = userDetails?.licenseData || [];
// const productAccess = licenseData[0].productAccess;
// const selectedSteps = productAccess === 'Zenith' ? PlanningWalkthrough : ResidentialWalkthrough;

export const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: ResidentialWalkthrough
  };
  
  export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "START":
        return { ...state, run: true };
      case "RESET":
        return { ...state, stepIndex: 0 };
      case "STOP":
        return { ...state, run: false };
      case "NEXT_OR_PREV":
        return { ...state, ...action.payload };
      case "RESTART":
        return {
          ...state,
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date()
        };
      default:
        return state;
    }
  };
  