import React, { useEffect, useRef, useState } from 'react';
import './TrainingManual.css';

const Accordion2 = ({ items, onLinkClick }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const accordionRef = useRef(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    if (accordionRef.current) {
      // Attach click events to links
      const links = accordionRef.current.querySelectorAll('a[data-video]');
      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const videoSrc = link.getAttribute('data-video');
          if (videoSrc && onLinkClick) {
            onLinkClick(videoSrc);
          }
        });
      });

      return () => {
        links.forEach((link) => {
          link.removeEventListener('click', () => {});
        });
      };
    }
  }, [activeIndex, onLinkClick]);

  return (
    <div className="accordion" ref={accordionRef}>
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion(index)}
          >
            {item.title}
            <span>
              {activeIndex === index ? (
                <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="35.1071" height="31.2679" rx="6.66055" fill="#543CA1"/>
                <path d="M11.6412 17.1936C11.4991 17.1936 11.3783 17.1438 11.2788 17.0444C11.1794 16.9591 11.1296 16.8383 11.1296 16.682V15.2753C11.1296 15.1332 11.1794 15.0125 11.2788 14.913C11.3783 14.8135 11.4991 14.7638 11.6412 14.7638H24.1523C24.3086 14.7638 24.4294 14.8135 24.5147 14.913C24.6141 15.0125 24.6639 15.1332 24.6639 15.2753V16.682C24.6639 16.8383 24.6141 16.9591 24.5147 17.0444C24.4294 17.1438 24.3086 17.1936 24.1523 17.1936H11.6412Z" fill="white"/>
                </svg>
                
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="32" viewBox="0 0 36 32" fill="none">
                  <rect width="35.1071" height="31.2679" rx="6.66055" fill="#F7F7FF"/>
                  <path d="M17.5406 8.72814C17.0118 8.72814 16.5831 9.15681 16.5831 9.6856V21.5825C16.5831 22.1112 17.0118 22.5399 17.5406 22.5399H17.5673C18.0961 22.5399 18.5248 22.1112 18.5248 21.5825V9.6856C18.5248 9.15681 18.0961 8.72814 17.5673 8.72814H17.5406Z" fill="#543CA1" stroke="#6F6C90" strokeWidth="0.249771" strokeLinecap="round"/>
                  <path d="M10.6936 14.7555C10.2085 14.7555 9.8153 15.1487 9.8153 15.6338C9.8153 16.1189 10.2085 16.5121 10.6936 16.5121H24.4139C24.899 16.5121 25.2923 16.1189 25.2923 15.6338C25.2923 15.1487 24.899 14.7555 24.4139 14.7555H10.6936Z" fill="#543CA1" stroke="#6F6C90" strokeWidth="0.249771" strokeLinecap="round"/>
                  </svg>
              )}
            </span>
          </div>
          {activeIndex === index && (
            <div className="accordion-content">
            {item.content}
          </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion2;
