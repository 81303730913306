import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ProtectiveRoutes  from "../Components/Utils/ProtectiveRoutes.js";
import RouteGuard  from "../Components/Utils/RouteGuard.js";
import Zenith from "../Components/Pages/Zenith/Zenith.js";
import Vertex from "../Components/Pages/Vertex/Vertex.js";
import Apex from "../Components/Pages/Apex/Apex.js";
import Sidebar from "../Components/Layouts/common/Sidebar.js";
import ShowSideNavBar from "../Components/Utils/ShowSideNavBar.js";
import ShowTopMenu from "../Components/Utils/ShowTopMenu.js";
import TopMenu from "../Components/Layouts/TopMenu.js";
import DashBoard from "../Components/Pages/Admin/DashBoard.js";
import UserList from "../Components/Pages/Admin/UserList.js";
import NewZenithProject from "../Components/Pages/Project/NewZenithProject.js";
import NewVertexProject from "../Components/Pages/Project/NewVertexProject.js";
import NewApexProject from "../Components/Pages/Project/NewApexProject.js";
import User from "../Components/Pages/Admin/User.js";
import UserHome from "../Components/Pages/UserHome/UserHome.js";
import UserReports from "../Components/Pages/UserReports/UserReports.js";
import TrainingManual from "../Components/Pages/TrainingManual/TrainingManual.js";
import Resources from "../Components/Pages/TrainingManual/Resources.js";
import PageNotFound from "../Components/Pages/PageNotFound.js";
import Login from "../Components/Pages/User/Login.js";
import Verify from "../Components/Pages/VerifyCode/Verify.js";
import Reset from "../Components/Pages/ResetPassword/Reset.js";
import Forgot from "../Components/Pages/ForgotPassword/Forgot.js";
import JoyRide from "../Components/Layouts/common/JoyRide.js"
import RequestAccess from "../Components/Pages/Admin/RequestAccess.js"
import { useReducer } from 'react';
 import { reducer, INITIAL_STATE } from '../Components/Layouts/common/tourReducer.js';
function App() {
  const userData = localStorage.getItem("UserInfo");
  const [collapsed, setCollapsed] = useState(false);
  const [startWalk, setStartWalk] = useState(false);
  const [sandboxIPopup, setSandboxIPopup] = useState(true);
  const clientName = localStorage.getItem("clientName");
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const startTour=()=>{
    dispatch({ type: "RESTART" });
    setStartWalk(true);
    console.log("tour started", startWalk);
}

const startWalkthrough = () => {
  setSandboxIPopup(false);
  setStartWalk(true);
  console.log("Pop up Tour",startWalk)
  sessionStorage.setItem('sandboxPopupShown', 'true');
};

  return (
    <Router>
      { clientName=="Blitz Sandbox" && <JoyRide startWalk={startWalk} setStartWalk={setStartWalk} key={tourState.key} dispatch={dispatch}/> }
      <AppContent startWalkthrough={startWalkthrough} userData={userData} collapsed={collapsed} setCollapsed={setCollapsed} startWalk={startWalk} setStartWalk={setStartWalk} startTour={startTour} setSandboxIPopup={setSandboxIPopup} sandboxIPopup={sandboxIPopup}/>
    </Router>
  );
}

const AppContent = ({ userData, collapsed, setCollapsed, startWalk, setStartWalk, startTour, setSandboxIPopup, sandboxIPopup, startWalkthrough }) => {
  const location = useLocation();

  return (
    <div className="sidebar-topMain">
      <div style={{ backgroundColor: "#ffffff" }}>
        {userData ? (
          <ShowSideNavBar>
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
          </ShowSideNavBar>
        ) : null}
      </div>

      <div
        className={
          [
            "/",
            "/verify-code",
            "/forgot-password",
            "/reset-password",
          ].includes(location.pathname)
            ? "side-flex-login"
            : `side-flex ${collapsed ? "collapsed" : ""}`
        }
      >
        {userData ? (
          <ShowTopMenu>
            <TopMenu startWalk={startWalk} setStartWalk={setStartWalk} startTour={startTour}/>
          </ShowTopMenu>
        ) : null}

        <Routes>
        
          <Route path="/" element={<Login />} />

          <Route path="/verify-code" element={<Verify />} />

          <Route path="/reset-password" element={<Reset />} />
          
          <Route path="/forgot-password" element={<Forgot />} />

        <Route element={<RouteGuard requiredProductId={1} />}>
          <Route path="/blitz-planning" element={<Zenith setCollapsed={setCollapsed} />} />
        </Route>

        <Route element={<RouteGuard requiredProductId={1} />}>
          <Route path="/new-blitz-planning-project" element={ <NewZenithProject setCollapsed={setCollapsed} />} />
        </Route>

        <Route element={<RouteGuard requiredProductId={2} />}>
          <Route path="/blitz-commercial-building" element={ <Vertex setCollapsed={setCollapsed} />} />
        </Route>

        <Route element={<RouteGuard requiredProductId={2} />}>
          <Route path="/new-blitz-commercial-building-project" element={ <NewVertexProject setCollapsed={setCollapsed} />} />
        </Route>

        <Route element={<RouteGuard requiredProductId={3} />}>
          <Route path="/blitz-residential-building" element={ <Apex setCollapsed={setCollapsed} />} />
        </Route>

        <Route element={<RouteGuard requiredProductId={3} />}>
          <Route path="/new-blitz-residential-building-project" element={ <NewApexProject setCollapsed={setCollapsed} />} />
        </Route>

          <Route
            path="/user-home"
            element={
                <ProtectiveRoutes>
                  <UserHome startWalk={startWalk} setStartWalk={setStartWalk} setSandboxIPopup={setSandboxIPopup} sandboxIPopup={sandboxIPopup} startWalkthrough={startWalkthrough}/>
                </ProtectiveRoutes>
            }
          />

          <Route path="/user-reports" element={
            <ProtectiveRoutes>
            <UserReports />
            </ProtectiveRoutes>
          } />

          <Route path="/training-manual" element={
            <ProtectiveRoutes>
            <TrainingManual />
            </ProtectiveRoutes>
          }/>

          <Route path="/resources" element={
            <ProtectiveRoutes>
            <Resources />
            </ProtectiveRoutes>
            } />

          <Route path="/dashboard" element={
             <ProtectiveRoutes>
                <DashBoard />
             </ProtectiveRoutes>
            } />

          <Route path="/user-list" element={
            <ProtectiveRoutes>
              <UserList />
            </ProtectiveRoutes>
      } />

          <Route path="/user" element={
            <ProtectiveRoutes>
            <User />
          </ProtectiveRoutes>
        } />

          <Route path="/request-access" element={
            <ProtectiveRoutes>
              <RequestAccess />
            </ProtectiveRoutes>  
              } />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        
      </div>
    </div>
  );
};

export default App;
