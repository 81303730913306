import React, {useEffect,useState} from "react";
import {useLocation} from "react-router-dom";
 
const ShowSideNavBar=({children})=>{
 
    const location=useLocation();
    const [showNavBar,setShowNavBar]=useState(false)
    useEffect(()=>{
        if(location.pathname==='/' ||
        location.pathname==='/verify-code' ||
        location.pathname==='/forgot-password' ||
        location.pathname==='/reset-password'
    ){
            setShowNavBar(false);
        }
        else{
            setShowNavBar(true);
        }
    },[location])
    return <div style={{backgroundColor:"#523CA2"}}>{showNavBar && children}</div>
}
 
export default ShowSideNavBar