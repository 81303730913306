import React,{useState} from "react";
import "./UserHome.css";
import BlitzPlanningLogo from "../../../Assets/Images/Blitz-Planning-Logo-Tile.png";
import BlitzBuildingRLogo from "../../../Assets/Images/Blitz-Building-Residential-Logo.png";
import BlitzBuildingCLogo from "../../../Assets/Images/Blitz-Building-Commercial-Logo.png";
import BlitzEngineeringLogo from "../../../Assets/Images/Blitz-Engineering-Logo-Tile.png";
import BlitzEnviornmentalLogo from "../../../Assets/Images/Blitz Environmental Logo.png";
import BlitzFireMarshalLogo from "../../../Assets/Images/Blitz-Firemarshal-Logo-Tile.png";
import BlitzHealthLogo from "../../../Assets/Images/Blitz Health Logo.png";
import BlitzPublicWorksLogo from "../../../Assets/Images/Blitz-Publicworks-Logo-Tile.png";
import BlitzTransportationLogo from "../../../Assets/Images/Blitz-Transportation-Logo-Tile.png";
import BlitzHistoricalPreservationLogo from "../../../Assets/Images/BlitzHistoricPreservation Logo-14.png";
import BlitzBuildingCR from "../../../Assets/Images/Blitz Building C+R logo.png";
import BlitzJarvizLogo from "../../../Assets/Images/Blitz-Jarviz-Logo-Tile.png";
import { Link } from "react-router-dom";
// import SandboxPopUp from "../../Layouts/common/SandboxPopUp";
import SandboxIPopup from "../../Layouts/common/SandboxWalkthrough";
import WalkthroughTooltip from "../../Layouts/common/WalkthroughTooltip";


function UserHome({startWalkthrough}) {
    // const [isPopupVisible, setIsPopupVisible] = useState(true);
    const [sandboxIPopup, setSandboxIPopup] = useState(true);
    const userDataInfo = localStorage.getItem("UserInfo");
    const userDetails = JSON.parse(userDataInfo);
    const licenseData = userDetails?.licenseData || [];
    const clientName = localStorage.getItem("clientName");

    const capitalizeFirstLetter=(Name)=>  Name.charAt(0).toUpperCase() + Name.slice(1);

    const isProductAccessible = (productId) => {
        return licenseData.some(item => item.productId === productId);
      };

    //   function ClosePopUp(){
    //     setIsPopupVisible(false);
    //     setSandboxIPopup(true);
    // }

  const closeWalkthrough = () => {
    setSandboxIPopup(false);
    sessionStorage.setItem('sandboxPopupShown', 'true');
  };

    return (
        <div className="main-div">
          
       {/* {
          clientName=="Blitz Sandbox" && isPopupVisible && (
          <div className={isPopupVisible ? "overlay" : ""}>
            <SandboxPopUp isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} ClosePopUp={ClosePopUp}/>
          </div>
         )
       } */}
      { clientName=="Blitz Sandbox" && sandboxIPopup && !sessionStorage.getItem('sandboxPopupShown') &&
      <div className={sandboxIPopup ? "overlay" : ""}>
         <SandboxIPopup setSandboxIPopup={setSandboxIPopup} sandboxIPopup={sandboxIPopup} closeWalkthrough={closeWalkthrough} startWalkthrough={startWalkthrough}/>
      </div>
      }
        <div className="row-1 dashboard-main-div" style={{borderBottom:"2px solid #E6E6E6", paddingBottom:"10px"}}>
          <div className="main-head">Home</div>
        </div>
        <div className="dashboard-div">
        <br/>
        <div className="Greeting-message-Dashboard" style={{textAlign:"center"}}>
            <h1>
            <span className="username-greeting">Hello {userDetails !== null
            ?capitalizeFirstLetter(userDetails?.firstName) + " " + capitalizeFirstLetter(userDetails?.surname) +","
            : "Taylor Blitz"}</span>&nbsp; 
            {
              clientName=="Blitz Sandbox" ? (
                <span>Welcome to Blitz Sandbox</span>
              ):(
                <span>Welcome to Blitz Permits</span>
              )
            }
            </h1>
            {
              clientName=="Blitz Sandbox" ? (
                <h5 style={{fontWeight:"400", color:"#686868"}}><i>Automated Plan Reviews, Making Housing Affordable</i></h5>
              ):(
                <h5 style={{fontWeight:"400", color:"#686868"}}>Please select below, for product specific analytics.<br/> Better technology for better governance.</h5>
              )
            }
            <br/>
            <h5> Enter workspace</h5>
        </div>
      </div>
      <WalkthroughTooltip position='bottom' content={
            <div>
              <p>Active products are displayed on your home screen upon login:<br/><br/>
                  &bull; Blitz Planning: For Plan and Zoning Reviews<br/>
                  &bull; Blitz Building: For Residential Building Code Reviews<br/>
              </p>
            </div>
          }>
      <div className="tilesDic" id='step2'>
        <Link to="/blitz-planning" style={{ pointerEvents: isProductAccessible(1) ? 'auto' : 'none', opacity: isProductAccessible(1) ? 1 : 0.6 }}>
        <div className="productCard" style={{backgroundColor: isProductAccessible(1) ? "#F2F9D1" : "#F8F6FF", position:"relative"}}>
          {isProductAccessible(1)?<svg width="29" height="29" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="ActiveProductThunder">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg> :null}
          <img src={BlitzPlanningLogo} className="ActiveProductTileImages" />
        </div>
        </Link>
        <Link to="/blitz-residential-building" style={{ pointerEvents: isProductAccessible(3) ? 'auto' : 'none', opacity: isProductAccessible(3) ? 1 : 0.6 }}>
        <div className="productCard" style={{backgroundColor: isProductAccessible(3) ? "#F2F9D1" : "#F8F6FF",position:"relative" }}>
        {isProductAccessible(3)?<svg width="29" height="29" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="ActiveProductThunder">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg> :null}
          <img src={BlitzBuildingRLogo} className="ActiveProductTileImages"/>
        </div>
        </Link>
        {
          clientName !=="Blitz Sandbox" && 
          <Link to="/blitz-commercial-building" style={{ pointerEvents: isProductAccessible(2) ? 'auto' : 'none', opacity: isProductAccessible(2) ? 1 : 0.6 }}>
            <div className="productCard" style={{backgroundColor: isProductAccessible(2) ? "#F2F9D1" : "#F8F6FF",position:"relative" }}>
            {isProductAccessible(2)?<svg width="29" height="29" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="ActiveProductThunder">
                                      <circle cx="19.5" cy="19.5" r="18.5" fill="#CEEA4A" stroke="#543CA1" strokeWidth="2"/>
                                      <path d="M18.6629 7.22534L11 21.2006L17.8558 21.6162L19.3711 31.5211L27 17.7891L19.5164 16.5545L18.6629 7.22534Z" fill="#543CA1"/>
                                  </svg> :null}
              <img src={BlitzBuildingCLogo} className="ActiveProductTileImages"/>
            </div>
          </Link>
        }
          
        {/* <Link to="https://jarviz.blitzpermits.ai/" style={{ pointerEvents: isProductAccessible(4) ? 'auto' : 'none', opacity: isProductAccessible(4) ? 1 : 0.6 }}>
            <div className="productCard" style={{backgroundColor: isProductAccessible(4) ? "#F2F9D1" : "#F8F6FF",position:"relative" }}>
            {isProductAccessible(4)?<img src={ThunderImage} className="ActiveProductThunder"/>:null}
              <img src={BlitzJarvizLogo} className="ActiveProductTileImages"/>
            </div>
        </Link>  */}
      </div>
      </WalkthroughTooltip>
      <div className="tilesDic2-parent">
      <WalkthroughTooltip position='top' content={
            <div>
              <p>Try out the Sandbox workflow! Unlock<br/> and start Blitzing other departments</p>
            </div>
          }>
      <div className="tilesDic2" style={{position:"relative"}}>
      <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="cardLockImage">
        <path d="M10.2507 37.583C9.31107 37.583 8.50701 37.2487 7.83848 36.5802C7.16996 35.9117 6.83512 35.1071 6.83398 34.1663V17.083C6.83398 16.1434 7.16882 15.3394 7.83848 14.6708C8.50815 14.0023 9.31221 13.6675 10.2507 13.6663H11.959V10.2497C11.959 7.88648 12.7921 5.87236 14.4583 4.2073C16.1245 2.54225 18.1386 1.70915 20.5007 1.70801C22.8627 1.70687 24.8774 2.53997 26.5447 4.2073C28.2121 5.87463 29.0446 7.88876 29.0423 10.2497V13.6663H30.7507C31.6902 13.6663 32.4949 14.0012 33.1645 14.6708C33.8342 15.3405 34.1685 16.1446 34.1673 17.083V34.1663C34.1673 35.1059 33.8331 35.9105 33.1645 36.5802C32.496 37.2499 31.6914 37.5841 30.7507 37.583H10.2507ZM20.5007 29.0413C21.4402 29.0413 22.2449 28.7071 22.9145 28.0386C23.5842 27.37 23.9185 26.5654 23.9173 25.6247C23.9162 24.684 23.5819 23.8799 22.9145 23.2125C22.2471 22.5451 21.4425 22.2103 20.5007 22.208C19.5588 22.2057 18.7547 22.5406 18.0885 23.2125C17.4222 23.8845 17.0874 24.6885 17.084 25.6247C17.0806 26.5608 17.4154 27.3655 18.0885 28.0386C18.7616 28.7116 19.5656 29.0459 20.5007 29.0413ZM15.3757 13.6663H25.6257V10.2497C25.6257 8.82606 25.1274 7.616 24.1309 6.61947C23.1343 5.62294 21.9243 5.12468 20.5007 5.12468C19.077 5.12468 17.867 5.62294 16.8704 6.61947C15.8739 7.616 15.3757 8.82606 15.3757 10.2497V13.6663Z" fill="#444444"/>
      </svg>
 
              {
                clientName =="Blitz Sandbox" && 
                <div className="">
                  <img src={BlitzBuildingCLogo} className="productTileImages"/>
                </div>
              }
              <div className="">
                <img src={BlitzJarvizLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzEngineeringLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzFireMarshalLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzTransportationLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzBuildingCR} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzEnviornmentalLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzPublicWorksLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzHistoricalPreservationLogo} className="productTileImages"/>
              </div>
              <div className="">
                <img src={BlitzHealthLogo} className="productTileImages"/>
              </div>
          </div>
            </WalkthroughTooltip>
      </div>
</div>
    );

}
export default UserHome;