import React from "react";
import {Navigate} from "react-router-dom"

const ProtectiveRoutes=({children})=>{
  const userData = localStorage.getItem("UserInfo");

  if (!userData) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectiveRoutes