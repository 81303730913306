import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { getSandboxUserList } from "../../../Storage/Redux/adminuserSlice";
import {userApproveDeny} from "../../../Storage/Redux/adminuserSlice";
import {registerSandbox} from "../../../Storage/Redux/adminuserSlice";
import SandboxImage from "../../../Assets/Images/blitz_sandbox.png";
import ApprovePopupBox from "../../Layouts/common/ApprovePopup";
import "./RequestAccess.css";

const RequestAccess = () => {
  const navigate = useNavigate();
  const showSuccessMsg = localStorage.getItem("listMessage");
  const [projectData, setProjectData] = useState([]);
  const [allProjectList, setAllProjectList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [closeMsgBox, setCloseMsgBox] = useState(showSuccessMsg);
  const [errorMsg, setErrorMsg] = useState("Project created successfully");
  const [iconRefresh, setIconRefresh] = useState(false);
  const [tabName, setTabName] = useState("Pending");
  const [addClass, setAddClass] = useState("active-tab");
  const [apiLoaded, setApiLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [listPerPage, setListPerPage] = useState(10);
  const [saveUi, setSaveUi] = useState("");
  const [CurrentData, setCurrentData] = useState([]);
  const urlSearch = useLocation();
  const queryData = urlSearch.search;
  const filter = queryData.split("&filter=");
  const filterValue = filter[1];
  const pageValue = filter[0].split("?page=")[1];
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [ApprovePopup,setApprovePopup] =useState(false);
  const [actionType, setActionType] = useState("");
  const dispatch = useDispatch();


  const fetchData = () => {
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);
    if (userInfo === null || undefined) {
      navigate("/");
    }

    console.log(setErrorMsg);
      dispatch(getSandboxUserList())
      .unwrap()
      .then((data) => {
        const reversedData = [...data].sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
        setAllProjectList(reversedData);
        setProjectData(reversedData);
        setIconRefresh(false);

        let filterData = [];
        localStorage.setItem("listMessage", false);

        setApiLoaded(true);
        
        if (filterValue === undefined && pageValue === undefined) {
          filterData = reversedData.filter((el) => el.status == 0);
          setSaveUi(callTableBody(filterData, currentPage, listPerPage, tabName));
        } else {
            setCurrentPage(pageValue);
            setTabName(filterValue);
          if (reversedData.length > 0 && filterValue == "Denied") {
            filterData = reversedData.filter((el) => el.status == -1);
            setSaveUi(
              callTableBody(filterData, pageValue, listPerPage, filterValue)
            );
          } else if (reversedData.length > 0 && filterValue == "Approved") {
            filterData = reversedData.filter((el) => el.status == 1);
            setSaveUi(
              callTableBody(filterData, pageValue, listPerPage, filterValue)
            );
          } else if (reversedData.length > 0 && filterValue == "Pending") {
            filterData = reversedData.filter((el) => el.status == 0);
            setSaveUi(
              callTableBody(filterData, pageValue, listPerPage, filterValue)
            );
          }
        }
        setTimeout(() => {
          setCloseMsgBox(false);
        }, 4000);
      })

      .catch((e) => {
        console.log(e, "project list");
      });
    }
      useEffect(() => {
        fetchData();
      }, []);

  const handleClick = (event, tabName, currentPage) => {
    const params = {
      page: currentPage,
      filter: tabName,
    };

    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    const encodedUrl = `?${queryString}`;
    const decodedUrl = decodeURIComponent(encodedUrl);
    navigate(decodedUrl);

    let filterData = [];

    if (tabName == "Approved") {
      let inProgressList = [...allProjectList];
      filterData = inProgressList.filter(
        (inpro) => inpro.status == 1
      );
    } else if (tabName == "Denied") {
      let inCompleteList = [...allProjectList];
      filterData = inCompleteList.filter(
        (inpro) => inpro.status == -1
      );
    } 
    else if (tabName == "Pending") {
      let allList = [...allProjectList];
      filterData = allList.filter(
        (inpro) => inpro.status == 0
      );
    }

    setCurrentData(filterData);
    setListPerPage(10);
    setCurrentPage(event.target.id);
    setSaveUi(callTableBody(filterData, event.target.id, listPerPage, tabName));
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(CurrentData.length / listPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        className={currentPage == number ? "active-list" : ""}
        key={number}
        id={number}
        onClick={(e) => handleClick(e, tabName, number)}
      >
        {number}
      </li>
    );
  });

  const handleCheckboxChange = (item) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = prevSelected.includes(item.email)
        ? prevSelected.filter((id) => id !== item.email)
        : [...prevSelected, item.email];

        return updatedSelected;
    });
  };

  useEffect(() => {
    console.log(selectedCheckboxes);
    let filterData = [];

    if (tabName == "Approved") {
      let inProgressList = [...allProjectList];
      filterData = inProgressList.filter(
        (inpro) => inpro.status == 1
      );
    } else if (tabName == "Denied") {
      let inCompleteList = [...allProjectList];
      filterData = inCompleteList.filter(
        (inpro) => inpro.status == -1
      );
    } 
    else if (tabName == "Pending") {
      let allList = [...allProjectList];
      filterData = allList.filter(
        (inpro) => inpro.status == 0
      );
    }

    const updatedUi = callTableBody( filterData, currentPage, listPerPage, tabName);
    setSaveUi(updatedUi);
  }, [ currentPage, listPerPage, tabName, selectedCheckboxes]);

  const handleApprove = () => {
    setActionType("approve"); 
    setApprovePopup(true);
  };

  const handleDeny = () => {
    setActionType("deny");
    setApprovePopup(true);
  };

  const handleConfirmAction = async () => {
    if (actionType === "approve") {
      await dispatch(userApproveDeny({
        userStatus: "approved",
        requestBody: selectedCheckboxes,
      }));
  
      setApprovePopup(false);

      for (const email of selectedCheckboxes) {
        const userDetails = allProjectList.find(item => item.email === email);
    
        if (userDetails) {
          const requestData = {
            adminId: "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
            clientId: 0,
            userType: "b_developer",
            roles: [""],
            middleName: "",
            mobile: "1234567890",
            mailingAddress: "",
            lineOfBusiness: "",
            username: userDetails.email,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            jobTitle: userDetails.jobTitle,
            companyName: userDetails.localJurisdiction,
            county: userDetails.cityCountyName,
            clientType: userDetails.clientType,
            state: userDetails.state,
            zipcode: userDetails.zipCode,
            products: [userDetails.productId],
            totalPermitApplications: userDetails.totalPermitApplications,
          };
          await dispatch(registerSandbox(requestData));
        }
      }
      
      await fetchData();  // Ensure fetchData is called after dispatch is completed.
      setSelectedCheckboxes([]);
  
    } else if (actionType === "deny") {
      await dispatch(userApproveDeny({
        userStatus: "denied",
        requestBody: selectedCheckboxes,
      }));
  
      setApprovePopup(false);
      await fetchData();  // Ensure fetchData is called after dispatch is completed.
      setSelectedCheckboxes([]);
    }
  };
  

  const callTableBody = (data, currentPage, listPerPage, tabName) => {

    let currentList = [];
    if (data.length < 10) {
      currentList = data;
      setCurrentData(data);
    } else {
      setCurrentData(data);
      const indexOfLastTodo = currentPage * listPerPage;
      const indexOfFirstTodo = indexOfLastTodo - listPerPage;
      currentList = data.slice(indexOfFirstTodo, indexOfLastTodo);
    }

    const renderprojectList =
      currentList &&
      currentList.map((item, key) => {
        return (
          <tbody className="table-body" key={key}>
            <tr className="table-row-tblrw" key={item.email}>
              <td className="td-projectlist-tbl" style={{whiteSpace:"nowrap"}}>{item.firstName + " " + item.lastName}</td>
              <td className="td-projectlist-tbl">{item.email}</td>
              <td className="td-projectlist-tbl" style={{whiteSpace:"nowrap"}}>{item.jobTitle}</td>
              <td className="td-projectlist-tbl">{item.localJurisdiction}</td>
              <td className="td-projectlist-tbl">{item.cityCountyName}</td>
              <td className="td-projectlist-tbl">{item.productDescription}</td>
              {tabName === "Pending" && (
                <td className="td-projectlist-tbl">
                  <input
                  className="sandboxApprovalCheckbox"
                    type="checkbox"
                    checked={selectedCheckboxes.includes(item.email)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                </td>
              )}
            </tr>
          </tbody>
        );
      });

    return renderprojectList;
  };

  const handleClass = (e, tabName) => {
    const encodedUrl = "";
    navigate(encodedUrl);

    let pageNo = 1;
    if (tabName == "Approved") {
      let inProgressList = [...allProjectList];
      let filterInprogress = inProgressList.filter(
        (inpro) => inpro.status == 1
      );

      setProjectData(filterInprogress);
      setSaveUi(callTableBody(filterInprogress, pageNo, listPerPage, tabName));
      setTabName("Approved");

      setAddClass("active-tab");
    } else if (tabName == "Denied") {
      let inCompleteList = [...allProjectList];
      let filterComplete = inCompleteList.filter(
        (inpro) => inpro.status == -1
      );

      setProjectData(filterComplete);
      setSaveUi(callTableBody(filterComplete, pageNo, listPerPage, tabName));
      setTabName("Denied");
      setAddClass("active-tab");
    } else if (tabName == "Pending") {
      let inCompleteList = [...allProjectList];
      let filterAll = inCompleteList.filter(
        (inpro) => inpro.status == 0
      );

      setProjectData(filterAll);
      setSaveUi(callTableBody(filterAll, pageNo, listPerPage, tabName));
      setTabName("Pending");
      setAddClass("active-tab");
    }
    setCurrentPage(pageNo);
  };


  const handleCloseMsg = () => {
    setCloseMsgBox("false");
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredList = projectData.filter(
      (user) =>
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.productDescription.toLowerCase().includes(query.toLowerCase()) ||
        user.firstName.toLowerCase().includes(query.toLowerCase()) ||
        user.cityCountyName.toLowerCase().includes(query.toLowerCase())
    );

    setSaveUi(callTableBody(filteredList, currentPage, listPerPage, tabName));
  };

  const handleRefresh = (e, tabName, currentPage) => {
    setIconRefresh(true);
    const params = {
      page: currentPage,
      filter: tabName,
    };
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    const encodedUrl = `?${queryString}`;
    navigate(encodedUrl);
    window.location.reload();
  };

  const closeApproveDenyPopup=()=>{
    setApprovePopup(false);
  }

  return (
    <>
      <div className="main-div">
          { ApprovePopup &&
            <div className={ApprovePopup ? "overlay" : ""}>
            <ApprovePopupBox closeApproveDenyPopup={closeApproveDenyPopup} selectedCount={selectedCheckboxes.length} handleConfirmAction={handleConfirmAction} actionType={actionType}/>
            </div>
          }
        <div className="top-row">
          <div className="active-heading">
            <h4 className="main-head">Request Access</h4>
          </div>
          <img src={SandboxImage} style={{height:"40px"}}/>
        </div>
        <div className="top-2-row-prog">
          <div className="toggle-list-prog">
            <ul>
              <li
                className={
                  tabName == "Pending" && addClass == "active-tab" ? addClass : ""
                }
                onClick={(e) => handleClass(e, "Pending", currentPage)}
              >
                Pending Users
              </li>
              <li
                className={
                  tabName == "Approved" && addClass == "active-tab"
                    ? addClass
                    : ""
                }
                onClick={(e) => handleClass(e, "Approved", currentPage)}
              >
               Approved Users
              </li>
              <li
                className={
                  tabName == "Denied" && addClass == "active-tab"
                    ? addClass
                    : ""
                }
                onClick={(e) => handleClass(e, "Denied", currentPage)}
              >
                Denied Users
              </li>
            </ul>
          </div>
          <div className="inner-box-user">
            <div className="search-container">
              <FontAwesomeIcon
                icon={faSearch}
                style={{ color: "#666666" }}
                className="search-icon"
              />
              <input
                className="input-box-user"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <button
              type="button"
              className="btn-project-refresh"
              onClick={(e) => handleRefresh(e, tabName, currentPage)}
            >
              <FontAwesomeIcon
                icon={faSync}
                size={"1px"}
                color={"#543CA1"}
                spin={iconRefresh}
              />
            </button>
          </div>
        </div>

        {closeMsgBox == "true" ? (
          <>
            <div className="message-div">
              <div className="inner-div">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#1E7137" }}
                />

                <span className="msg-text">{errorMsg}</span>
              </div>
              <div>
                <span onClick={handleCloseMsg} className="cross">
                  {" "}
                  X{" "}
                </span>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="table-div-completed">
              <table className="table table-borderless">
                <thead>
                  <tr className="table-heading">
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>User Name</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Work Email</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Job Title</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Company/Organization</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>City/County</th>
                    <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Product</th>
                    { tabName == "Pending" &&
                      <th className="th-projectlist-tbl" style={{whiteSpace:"nowrap"}}>Action</th>
                    }
                  </tr>
                </thead>
                {saveUi && saveUi.length > 0 ? (
                  saveUi
                ) : (
                  saveUi.length === 0 &&
                  apiLoaded && <tbody></tbody>
                )}
              </table>
        </div>
          <div className="next-page-icons">
          {saveUi.length !== 0 && tabName=='Pending' && <div className="Approve-deny-parent">
              <button
                  onClick={handleApprove}
                  disabled={selectedCheckboxes.length == 0}
                  className={`ApproveButton ${selectedCheckboxes.length > 0 ? "active" : "disabled"}`}
                >
                  Approve
                </button>
                <button
                  onClick={handleDeny}
                  disabled={selectedCheckboxes.length == 0}
                  className={`DenyButton ${selectedCheckboxes.length > 0 ? "active" : "disabled"}`}
                >
                  Deny
                </button>
            </div>  
          }
            <ul className="next-page-list">
              {renderPageNumbers}
            </ul>
          </div>
      </div>
    </>
  );
};
export default RequestAccess;
