import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
 import UserCard from "./common/UserCard";
import "./TopMenu.css";
 import WalkthroughTooltip from '../Layouts/common/WalkthroughTooltip';
 
const TopMenu = ({startTour}) => {
  let navigate = useNavigate();

  const [CardVisible, setCardVisible] = useState(false);
  
  const userDataInfo = localStorage.getItem("UserInfo");
  const userDetails = JSON.parse(userDataInfo);
  const nameArray = userDetails?.firstName + userDetails?.surname;
  let firstNameArray=userDetails?.firstName;
  let lastNameArray=userDetails?.surname;
  if(nameArray !==undefined){
    firstNameArray = firstNameArray[0];
    lastNameArray = lastNameArray[0] || ' ';
  }
  const clientName = localStorage.getItem("clientName");
  const getInitials = () => {
    return `${firstNameArray[0]}${lastNameArray[0]}`.toUpperCase();
  };

  const handleEmail = () => {
    const userEmail = userDetails?.email
      ? userDetails.email
      : "taylor.blitz@blitzpermits.ai";
    return `${userEmail}`;
  };

  const handleLogout=()=>{
  localStorage.clear();
  sessionStorage.clear();
  navigate("/");
  window.location.reload();
  }

  const handleRedirect=()=>{
    const userData = localStorage.getItem("UserInfo");
    const userInfo = JSON.parse(userData);
    if (userInfo?.userType === "admin") {
      navigate("/dashboard");
    }
    else{
      navigate("/user-home");
    }
  }

  const handleUserProfile = () => {
    setCardVisible(true);
  };
  
  return (
    <div>
      <div>
        <nav className="nav-bar">
            <div style={{"cursor":"pointer"}} onClick={handleRedirect}>
              <img
                src={require("../../Assets/Images/blitzpermits_logo_light.png")}
                alt="logo"
                className="main-logo"
              />
            </div>
          {/* <div className="middle-col">
            {userDetails?.userType === "admin" ? (
              <>
                {" "}
                <div className="tabs-flex">
                  <Link to="/dashboard">
                    <div
                      className={
                        tabValue == "dashboard" && addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "dashboard")}
                    >
                      Dashboard
                    </div>
                  </Link>
                  <Link to="/user-list">
                    {" "}
                    <div
                      className={
                        tabValue == "manage_users" &&
                        addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "manage_users")}
                    >
                      Manage Users
                    </div>{" "}
                  </Link>
                  <Link to="/invoice">
                    {" "}
                    <div
                      className={
                        tabValue == "invoice" && addClass == "flex-item-new"
                          ? "flex-item-new"
                          : "flex-item"
                      }
                      onClick={(e) => handleTabColor(e, "invoice")}
                    >
                      Invoice
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              ""
            )}
          </div> */}
            <div className="col-3">
            <WalkthroughTooltip position='bottom' content={
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", paddingBottom:"10px"}}>
              <p>Get a guided tour of<br/> the Blitz Sandbox</p>
              <button className="tour-button" onClick={startTour}>Start now</button>
            </div>
            }>
            { clientName=="Blitz Sandbox" &&
              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" className="i-icon" id="step13">
              <g clipPath="url(#clip0_819_4789)">
              <path d="M18.9698 31.8442H23.0321V19.6573H18.9698V31.8442ZM21.0009 15.595C21.5764 15.595 22.0591 15.4 22.4491 15.01C22.8391 14.62 23.0334 14.138 23.0321 13.5638C23.0307 12.9897 22.8357 12.5076 22.4471 12.1177C22.0585 11.7277 21.5764 11.5327 21.0009 11.5327C20.4254 11.5327 19.9434 11.7277 19.5547 12.1177C19.1661 12.5076 18.9711 12.9897 18.9698 13.5638C18.9684 14.138 19.1634 14.6207 19.5547 15.012C19.9461 15.4034 20.4281 15.5977 21.0009 15.595ZM21.0009 41.9999C18.1912 41.9999 15.5507 41.4664 13.0794 40.3994C10.6082 39.3323 8.45859 37.8855 6.63056 36.0588C4.80253 34.2321 3.35567 32.0825 2.29 29.6099C1.22432 27.1373 0.69081 24.4968 0.689456 21.6884C0.688102 18.88 1.22162 16.2395 2.29 13.767C3.35838 11.2944 4.80523 9.14474 6.63056 7.31806C8.45588 5.49138 10.6055 4.04453 13.0794 2.9775C15.5534 1.91047 18.1939 1.37695 21.0009 1.37695C23.808 1.37695 26.4485 1.91047 28.9224 2.9775C31.3963 4.04453 33.546 5.49138 35.3713 7.31806C37.1966 9.14474 38.6441 11.2944 39.7139 13.767C40.7836 16.2395 41.3164 18.88 41.3124 21.6884C41.3083 24.4968 40.7748 27.1373 39.7118 29.6099C38.6489 32.0825 37.202 34.2321 35.3713 36.0588C33.5405 37.8855 31.3909 39.333 28.9224 40.4014C26.4539 41.4698 23.8134 42.0026 21.0009 41.9999Z" fill="#35236E"/>
              <path d="M21.0009 15.595C21.5764 15.595 22.0591 15.4 22.4491 15.01C22.8391 14.62 23.0334 14.138 23.0321 13.5638C23.0307 12.9897 22.8357 12.5076 22.4471 12.1177C22.0585 11.7277 21.5764 11.5327 21.0009 11.5327C20.4254 11.5327 19.9434 11.7277 19.5547 12.1177C19.1661 12.5076 18.9711 12.9897 18.9698 13.5638C18.9684 14.138 19.1634 14.6207 19.5547 15.012C19.9461 15.4034 20.4281 15.5977 21.0009 15.595Z" fill="white"/>
              <path d="M18.9698 31.8442H23.0321V19.6573H18.9698V31.8442Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_819_4789">
              <rect width="42" height="42" fill="white"/>
              </clipPath>
              </defs>
              </svg>
               
             } 
            </WalkthroughTooltip>
              <div style={{cursor:"pointer"}} onClick={handleUserProfile} className={`bg-yellow ${
                    CardVisible ? "clicked" : ""
                  }`}>
                    {getInitials()}
              </div>
                {CardVisible && (
                  <UserCard
                    setCardVisible={setCardVisible}
                    getInitials={getInitials}
                    handleEmail={handleEmail}
                    handleLogout={handleLogout}
                  />
                )}
            </div>
        </nav>
      </div>
    </div>
  );
};
 
export default TopMenu;