import React from 'react';
import X from "../../../Assets/Images/x.png";
import './ApprovePopup.css';

const ApprovePopup = ({closeApproveDenyPopup, selectedCount, handleConfirmAction, actionType}) => {
  return (
    <div>
        <div className='approve-popup-parent'>
            <p style={{marginBottom:"0px"}}>Are you sure you want to {actionType === "approve" ? "approve" : "deny"} {selectedCount} request ?</p>
            <div className='approve-deny-div'>
                <button className='button-prime' onClick={handleConfirmAction}>Yes</button>
                <button className="button-sec" onClick={closeApproveDenyPopup}>Cancel</button>
            </div>
            <img src={X} className='approveX' onClick={closeApproveDenyPopup} />
        </div>
    </div>
  )
}

export default ApprovePopup