import React, { useState } from "react";
import "./WalkthroughTooltip.css";
// import Polygon from "../../../Assets/Images/Polygon.png";

const WalkthroughTooltip = ({ children, content, position = "bottom"}) => {
  const [visible, setVisible] = useState(false);
  const clientName = localStorage.getItem("clientName");

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <>
    
    <div
      className="tooltip-container2"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
        
      {children}
      {visible && clientName =="Blitz Sandbox" && (
        <div className={`tooltip-box tooltip-${position}`}>
          {/* <img src={Polygon} className="Polygon-sandbox"/> */}
            {content}
        </div>
      )}
    </div>
    </>
  );
};

export default WalkthroughTooltip;
